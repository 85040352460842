import {Component, Input, OnInit} from '@angular/core'
import {LocalStorageService} from '../../services/local-storage.service'
import {ActivatedRoute, Router} from '@angular/router'
import {DialogService} from '../../services/dialog.service'
import {OidcSecurityService} from 'angular-auth-oidc-client'
import {ActivityLogService} from '../../services/activity-log.service'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [LocalStorageService]
})
export class LoginComponent implements OnInit {
  @Input() isSessionExpired = false

  private returnUrl: string

  constructor(
    private router: Router,
    private localStorageService: LocalStorageService,
    private activityLogService: ActivityLogService,
    private route: ActivatedRoute,
    private dialog: DialogService,
    private oidcSecurityService: OidcSecurityService
  ) {
  }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/homepage'

    this.oidcSecurityService.isAuthenticated().subscribe(isAuthenticated => {
      if (isAuthenticated) {
        this.navigateToReturnUrl()
      }
    })
  }

  login() {
    this.activityLogService.log('Login')
    this.oidcSecurityService.authorize()
    this.navigateToReturnUrl()
  }

  private navigateToReturnUrl(): void {
    this.router.navigateByUrl(this.returnUrl)
  }
}
