import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'StringContainsFilterPipe',
  pure: false
})
export class StringContainsFilterPipe implements PipeTransform {
  transform(value: any, input: any) {
    if (input) {
      return value.filter((el: any) => {

        let filterResult = true;

        for (const key in input) {
          if (input.hasOwnProperty(key)) {
            if (key === 'disabled') {
              return
            }
            if (input[key] && input[key].length > 0) {
              const str = String(el[key]);
              if (str) {
                filterResult = filterResult && str.toLowerCase().indexOf(input[key].toLowerCase()) > -1
              } else {
                filterResult = false
              }
            }
          }
        }
        return filterResult;
      })
    }

    return value;
  }
}
