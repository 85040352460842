import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http'
import {Environment} from '../app.environment'
import {LocalStorageService} from './local-storage.service'
import {Observable} from 'rxjs/internal/Observable'
import {News} from '../components/news/news';

@Injectable({
  providedIn: 'root'
})
export class NewsService {

  constructor(private httpClient: HttpClient,
              private environment: Environment,
              private localStorageService: LocalStorageService) {
  }

  updateNewsItemFavorite(news: News): Observable<any> {
    const url = this.environment.updateNewsItemFavorite(this.localStorageService.getUserId(), news.id, news.type)
    return this.httpClient.put(url, news)
  }
}
