import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http'
import {Environment} from '../app.environment'
import {LocalStorageService} from './local-storage.service'
import {Observable} from 'rxjs/internal/Observable'
import {Homepage} from '../components/homepage/homepage';

@Injectable({
  providedIn: 'root'
})
export class HomepageService {

  constructor(private httpClient: HttpClient,
              private environment: Environment,
              private localStorageService: LocalStorageService) {
  }

  getHomepageContent(): Observable<Homepage> {
    const url = this.environment.getHomepageUrl(this.localStorageService.getUserId())
    return this.httpClient.get<Homepage>(url)
  }
}
