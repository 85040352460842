import {Component, OnInit} from '@angular/core'
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap'

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {

  title = ''
  showCancelBtn = true
  message = ''
  actionButtonTitle = 'Reload'
  actionButtonClass = ''
  cancelButtonTitle = 'Cancel'

  constructor(public activeModal: NgbActiveModal) {
  }

  ngOnInit() {
  }

  actionButtonPressed() {
    this.activeModal.close(this.actionButtonTitle)
  }

  cancelButtonPressed() {
    this.activeModal.close(this.cancelButtonTitle)
  }
}
