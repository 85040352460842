import {NgModule} from '@angular/core'
import {RouterModule, Routes} from '@angular/router'
import {LoginComponent} from './components/login/login.component'
import {SharepointComponent} from './components/resources/sharepoint/sharepoint.component'
import {HomepageComponent} from './components/homepage/homepage.component';
import {AutoLoginPartialRoutesGuard} from 'angular-auth-oidc-client';
import {RESOURCE_SITE} from './components/resources/RESOURCE_SITE.enum';

const routes: Routes = [
  {path: '', redirectTo: '/login', pathMatch: 'full'},
  {path: 'login', component: LoginComponent},
  {path: 'sharepoint/:siteId', component: SharepointComponent, canActivate: [AutoLoginPartialRoutesGuard]},
  {path: 'sharepoint/:siteId/:folderId', component: SharepointComponent, canActivate: [AutoLoginPartialRoutesGuard]},
  {path: 'homepage', component: HomepageComponent, canActivate: [AutoLoginPartialRoutesGuard]},
  {path: 'tipsheets', redirectTo: '/sharepoint/' + RESOURCE_SITE.HUBBL},
  {path: '**', redirectTo: 'homepage'}
]

/* For future if res needs to be shown
  {path: 'creative-commons', component: CreativeCommonsComponent},
{
    path: 'events', component: EventsComponent, canActivate: [AuthenticationGuard]
  },
  {
    path: 'events/:eventId', component: EventDetailsComponent, canActivate: [AuthenticationGuard]
  },
  {path: 'news-details', component: NewsDetailComponent, canActivate: [AuthenticationGuard]},

{
  path: 'resources',
  component: ResourcesComponent,
  canActivate: [AuthenticationGuard],
  children: [
    {
      path: 'sharepoint',
      component: SharepointComponent,
      canActivate: [AuthenticationGuard]
    },
    {
      path: 'news-source',
      component: NewsSourceComponent,
      canActivate: [AuthenticationGuard]
    }
  ]
},
 */

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true,
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
