<div class="container">
  <div *ngIf="!isLoading && !hasError">
    <div class="row">
      <div class="col-7">

        <div class="event-title" [ngClass]="{'event-marker':eventCal.multiDay}">
          {{eventCal.title}}
        </div>

        <div class="event-date">
          <div><i class="fas fa-clock mr-2 " [ngClass]="{'event-marker':eventCal.multiDay}"></i>{{getEventDate()}}</div>
          <div><i class="event-time"></i>{{getEventTime()}}</div>
          <!--<div class="event-calendar" *ngIf="eventCal.fullDateTime" (click)="onAddToCalendar(eventCal)">-->
            <!--<i class="event-time"></i>Add to calendar-->
          <!--</div>-->
        </div>

        <div class="event-locations">
          <div class="event-location" *ngFor="let location of eventCal.locations" (click)="onLocation(location)">
            <span><i class="fas fa-map-marker-alt mr-2 event-marker" [ngClass]="{'event-marker':eventCal.multiDay}"></i></span>
            <span> {{location.name}}</span>
          </div>
        </div>

      </div>

      <div class="col-5">
        <div *ngIf="eventCal.imageUrl">
          <img class="event-image float-right img-thumbnail" src="{{eventCal.imageUrl}}"
               onerror="this.style.display='none'">
        </div>
      </div>
    </div>

    <div class="event-descriptionLabel" *ngIf="hasDescription(eventCal)">Description</div>

    <div class="event-description" *ngIf="hasDescription(eventCal)" [innerHtml]="eventCal.description"></div>

    <div class="event-viewmore" *ngIf="hasUrl(eventCal)" (click)="onViewMore(eventCal.url)">
      View More
    </div>
  </div>
  <div *ngIf="isLoading" class="text-center container4"><p>Loading... <i class="fa fa-spinner fa-spin"></i></div>
</div>


