<div>

  <header class="text-center mt-4 mb-5">
    <h5><b>Creative Commons</b></h5>
  </header>

  <div>All icons used are courtesy of Font Awesome and Creative Commons. Icons were colored, resized
    and, in some instances,
    given a background.
  </div>

  <div class="mt-3 mb-3">Icons used:</div>
  <ul>
    <li>
      <span>newspaper: </span>
      <a href="https://fontawesome.com/icons/newspaper?style=solid">https://fontawesome.com/icons/newspaper?style=solid</a>
    </li>
    <li>
      <span>virus: </span>
      <a href="https://fontawesome.com/icons/virus?style=solid">https://fontawesome.com/icons/virus?style=solid</a>
    </li>
    <li>
      <span>user-friends: </span>
      <a href="https://fontawesome.com/icons/user-friends?style=solid">https://fontawesome.com/icons/user-friends?style=solid</a>
    </li>
    <li>
      <span>microphone: </span>
      <a href="https://fontawesome.com/icons/microphone?style=solid">https://fontawesome.com/icons/microphone?style=solid</a>
    </li>
    <li>
      <span>seedling: </span>
      <a href="https://fontawesome.com/icons/seedling?style=solid">https://fontawesome.com/icons/seedling?style=solid</a>
    </li>
    <li style="margin-left:2em">sponsored by The us-Sunnah Foundation</li>
    <li>
      <span>mobile-alt (phone): </span>
      <a href="https://fontawesome.com/icons/mobile-alt?style=solid">https://fontawesome.com/icons/mobile-alt?style=solid</a>
    </li>
    <li>
      <span>hospital-alt: </span>
      <a href="https://fontawesome.com/icons/hospital-alt?style=solid">https://fontawesome.com/icons/hospital-alt?style=solid</a>
    </li>
    <li style="margin-left:2em">sponsored by Harvard Medical School</li>
    <li>
      <span>shapes: </span>
      <a href="https://fontawesome.com/icons/shapes?style=solid">https://fontawesome.com/icons/shapes?style=solid</a>
    </li>
    <li>
      <span>check-solid: </span>
      <a href="https://fontawesome.com/icons/check?style=solid">https://fontawesome.com/icons/check?style=solid</a>
    </li>
    <li>
      <span>share: </span>
      <a href="https://fontawesome.com/icons/share-square?style=solid">https://fontawesome.com/icons/share-square?style=solid</a>
    </li>
    <li>
      <span>users: </span>
      <a href="https://fontawesome.com/icons/users?style=solid">https://fontawesome.com/icons/users?style=solid</a>
    </li>
    <li>
      <span>user: </span>
      <a href="https://fontawesome.com/icons/user?style=solid">https://fontawesome.com/icons/user?style=solid</a>
    </li>
    <li>
      <span>clock: </span>
      <a href="https://fontawesome.com/icons/clock?style=solid">https://fontawesome.com/icons/clock?style=solid</a>
    </li>
    <li>
      <span>map marker: </span>
      <a href="https://fontawesome.com/icons/map-marker-alt?style=solid">https://fontawesome.com/icons/map-marker-alt?style=solid</a>
    </li>
    <li>
      <span>filter: </span>
      <a href="https://fontawesome.com/icons/filter?style=solid">https://fontawesome.com/icons/filter?style=solid</a>
    </li>
    <li>
      <span>Compass by Yo! Baba from the Noun Project: </span>
      <a href="https://thenounproject.com/search/?q=compass&i=924202">https://thenounproject.com/search/?q=compass&i=924202</a>
    </li>
  </ul>
  <div class="mt-3 mb-3">
    <span>Icons available for use under Public License:</span>
    <a href="https://creativecommons.org/licenses/by/4.0/legalcode">https://creativecommons.org/licenses/by/4.0/legalcode</a>
  </div>

</div>
