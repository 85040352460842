<div class="news-source" [ngStyle]="{'color':news.source.textColor}">
  {{news.source.title}}
</div>
<div class="news-video" [innerHtml]="sanitizeVideoUrl()" *ngIf="showVideo()">
</div>
<div *ngIf="!showVideo()" class="news-thumbnail">
  <img src="{{news.thumbnailUrl}}" onerror="this.style.display='none'">
</div>
<div [ngStyle]="{'background-color': news.backgroundColor}">
  <div class="news-title">
    {{news.title}}
  </div>
  <div class="news-date">
    {{news.startDate| date: 'MMM d, yyyy'}}
  </div>
</div>
<div class="news-description" [innerHtml]="news.descriptionAsHtml">
</div>
<div class="news-viewmore" *ngIf="news.attachmentUrl" (click)="onAttachment()">
  View More
</div>

