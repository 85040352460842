import {Component, OnInit} from '@angular/core'
import {NgbActiveModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap'
import {ActivityLogService} from '../../../services/activity-log.service'
import {TermsOfUse} from './terms-of-use'
import {ChatService} from '../../../services/chat.service'

@Component({
  selector: 'app-chat-splash',
  templateUrl: './chat-splash.component.html',
  styleUrls: ['./chat-splash.component.scss']
})
export class ChatSplashComponent implements OnInit {

  static ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    size: 'xl'
  }

  termsOfUse: TermsOfUse
  isLoadingTermsOfUse = false

  constructor(private chatService: ChatService,
              private activeModal: NgbActiveModal,
              private activityLogService: ActivityLogService) {
  }

  ngOnInit(): void {
    if (!this.termsOfUse) {
      this.isLoadingTermsOfUse = true
      this.chatService.getTermsOfUse().subscribe({
        next: termsOfUse => {
          this.termsOfUse = termsOfUse
          this.isLoadingTermsOfUse = false
        },
        error: () => {
          this.termsOfUse = new TermsOfUse()
          this.termsOfUse.version = -1
          this.termsOfUse.body = 'This is a private AI Chat (OpenAI/ChatGPT) service hosted at Vanderbilt University Medical Center. '
            + 'Given it is a private service where usage data are not passed along to developers or development models outside VUMC, '
            + 'we recommend VUMC users choose this interface rather than using public services.<br/><br/>'
            + 'By use of this system, you are agreeing to the guidelines and principles below:<ul>'
            + '<li>You will not use information derived from the service to inform clinical care</li>'
            + '<li>You understand that responses provided by the service have not been validated and you are responsible for '
            + 'appropriate validation before use of any information returned</li>'
            + '<li>You understand that your prompts and responses may be reviewed within VUMC for the purpose of '
            + 'improving and tuning the service</li>'
            + '<li>You will not leverage the service to run automated workflows</li>'
            + '</ul>'
          this.isLoadingTermsOfUse = false
        }
      })
    }
  }

  setTermsOfUse(termsOfUse: TermsOfUse) {
    this.termsOfUse = termsOfUse
  }

  onAgree() {
    if (!!this.termsOfUse) {
      this.activityLogService.log('Terms of Use - agree')
      this.chatService.acknowledgeTermsOfUse(this.termsOfUse.version).subscribe({
        next: () => {
          this.activeModal.close('agree')
        },
        error: () => {
          this.activeModal.close('error-acknowledging')
        }
      })
    } else {
      this.activeModal.close('no-terms')
    }
  }
}
