import {Environment} from './app.environment'

const appEnvironmentFactory = () => {
  return new Environment(window.location.hostname,window)
}

export let EnvironmentProvider = {
  provide: Environment,
  useFactory: appEnvironmentFactory
}
