import {Injectable} from '@angular/core'
import {Environment} from '../app.environment'
import {HttpClient} from '@angular/common/http'
import {Observable} from 'rxjs'
import {ChatResponse} from '../components/chat/chat-response'
import {ChatRequest} from '../components/chat/chat-request'
import {TermsOfUse} from '../components/chat/chat-splash/terms-of-use'
import {of} from 'rxjs/internal/observable/of'
import {ChatTypeConfiguration} from '../components/chat/chat-type-configuration'

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  constructor(private http: HttpClient, private env: Environment) {
  }

  chat(request: ChatRequest): Observable<ChatResponse> {
    return this.http.post<ChatResponse>(this.env.getChatUrl(), request)
    // .pipe(map(this.replaceNewLineWithBreak()))
  }

  private replaceNewLineWithBreak() {
    return (response: ChatResponse) => {
      if (!!response && !!response.messages && response.messages.length > 0) {
        for (const message of response.messages) {
          if (!!message && !!message.content) {
            message.content = message.content.replace(/\n/g, '<br/>')
          }
        }
      }
      return response
    }
  }

  getChatTypeBySiteId(siteId: string): Observable<ChatTypeConfiguration> {
    return this.http.get<ChatTypeConfiguration>(this.env.getChatTypeBySiteIdUrl(siteId))
  }

  getTermsOfUse(): Observable<TermsOfUse> {
    return this.http.get<TermsOfUse>(this.env.getTermsOfUseUrl())
  }

  acknowledgeTermsOfUse(version: number): Observable<string> {
    return this.http.post<string>(this.env.getTermsOfUseAcknowledgeUrl(version), {})
  }
}
