import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {ResourcesService} from "../../services/resources-service";
import {Resource} from "../resources/resource";
import {Homepage} from "./homepage";
import {RESOURCE_SITE} from '../resources/RESOURCE_SITE.enum'
import {ActivityLogService} from '../../services/activity-log.service';


@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit {

  isLoading = false
  hasError = false

  selectedResourceId: number
  homepageContent: Homepage
  siteTypes = RESOURCE_SITE

  constructor(private router: Router,
              private activityLogService: ActivityLogService,
              private resourcesService: ResourcesService) {
  }

  ngOnInit(): void {

  }

  onResourceSelection(siteId: string) {
    this.isLoading = true
    this.resourcesService.getResource(siteId).subscribe(resource => {
        this.router.navigate(['/sharepoint/'+siteId, {
            siteId: resource.siteId,
            displayName: resource.displayName
        }])
        this.isLoading = false
      },
      () => {
        this.isLoading = false
        this.hasError = true
      })

  }

}
