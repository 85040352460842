import {Injectable} from '@angular/core'
import {Location} from '../components/events/location';

@Injectable({
  providedIn: 'root'
})
export class LocationMapUtil {

  static getLocationSearchQuery(location: Location): string {
    let searchQuery = ''

    if (location) {
      if (location.addressLine1 && location.addressLine1.length > 0) {
        searchQuery = searchQuery.concat(this.replaceEmptyStringWithPlusSign(location.addressLine1))
      }
      if (location.addressLine2 && location.addressLine2.length > 0) {
        searchQuery = searchQuery.concat(this.replaceEmptyStringWithPlusSign(location.addressLine2))
      }
      if (location.city && location.city.trim().length > 0) {
        searchQuery = searchQuery.concat('+').concat(this.replaceEmptyStringWithPlusSign(location.city))
      }
      if (location.state && location.state.trim().length > 0) {
        searchQuery = searchQuery.concat('+').concat(this.replaceEmptyStringWithPlusSign(location.state))
      }
      if (location.zip && location.zip.trim().length > 0) {
        searchQuery = searchQuery.concat('+').concat(location.zip.trim())
      }
    }
    return searchQuery
  }

  static getMapsLink(location: Location) {
    const searchQuery = this.getLocationSearchQuery(location)
    if (searchQuery && searchQuery.trim().length > 0) {
      return 'https://www.google.com/maps/search/?api=1&query=' + searchQuery;
    }
    return ''
  }

  static replaceEmptyStringWithPlusSign(str: string) {
    let val = str.trim().replace(/\s+/g, ' ')
    val = val.replace(/\s+/g, '+')
    return val
  }

}
