import {Injectable} from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  private userIdKey = 'VUMC2GO_WEB_USER_ID'
  private userRolesKey = 'VUMC2GO_WEB_USER_ROLES'
  private tokenKey = 'VUMC2GO_WEB_AUTH_TOKEN'

  constructor() {
  }

  setToken(token: string) {
    localStorage.setItem(this.tokenKey, token)
  }

  getToken(): string {
    return localStorage.getItem(this.tokenKey)
  }

  removeToken() {
    localStorage.removeItem(this.tokenKey)
  }

  setUserId(userId: string) {
    localStorage.setItem(this.userIdKey, userId)
  }

  getUserId(): string {
    return localStorage.getItem(this.userIdKey)
  }

  removeUserId() {
    localStorage.removeItem(this.userIdKey)
  }

  setUserRoles(roles: string[]) {
    localStorage.setItem(this.userRolesKey, JSON.stringify(roles))
  }

  getUserRoles(): string[] {
    let rolesStr = localStorage.getItem(this.userRolesKey)
    return !!rolesStr ? [].concat(JSON.parse(rolesStr)) : []
  }

  removeUserRoles() {
    localStorage.removeItem(this.userRolesKey)
  }

  clear() {
    this.removeToken()
    this.removeUserId()
    this.removeUserRoles()
  }
}
