export class SharepointFile {

  id: string
  name: string
  favorite: boolean
  showNew: boolean
  siteId: string
  path: string

  url: string
  extension: string
}
