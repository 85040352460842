import {BrowserModule} from '@angular/platform-browser'
import {NgModule} from '@angular/core'

import {AppRoutingModule} from './app-routing.module'
import {AppComponent} from './app.component'
import {EnvironmentProvider} from './app.environment.provider'
import {LoginComponent} from './components/login/login.component'
import {FormsModule, ReactiveFormsModule} from '@angular/forms'
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http'
import {NgbModalModule, NgbModule} from '@ng-bootstrap/ng-bootstrap'
import {AutofocusDirective} from './util/autofocus.directive'
import {ResourcesComponent} from './components/resources/resources.component'
import {SharepointComponent} from './components/resources/sharepoint/sharepoint.component'
import {CreativeCommonsComponent} from './components/creative-commons/creative-commons.component'
import {NewsSourceComponent} from './components/resources/news-source/news-source.component'
import {NewsDetailComponent} from './components/news/newsDetail/news-detail.component'
import {NgxExtendedPdfViewerModule} from 'ngx-extended-pdf-viewer'
import {EventsComponent} from './components/events/events.component'
import {EventDetailsComponent} from './components/events/eventDetails/event-detail.component'
import {StringContainsFilterPipe} from './util/stringcontainsfilter.pipe'
import {HomepageComponent} from './components/homepage/homepage.component'
import {NgSelectModule} from '@ng-select/ng-select'
import {NgOptionHighlightModule} from '@ng-select/ng-option-highlight'
import {DialogComponent} from './components/dialog/dialog.component'
import {AuthConfigModule} from './app.authentication'
import {AuthInterceptor} from 'angular-auth-oidc-client'
import {ApiJsonInterceptor} from './interceptors/api-json.interceptor'
import {MediaViewerComponent} from './components/resources/sharepoint/media-viewer/media-viewer.component'
import {ChatComponent} from './components/chat/chat.component'
import {ChatSplashComponent} from './components/chat/chat-splash/chat-splash.component'

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ResourcesComponent,
    AutofocusDirective,
    SharepointComponent,
    CreativeCommonsComponent,
    NewsDetailComponent,
    AutofocusDirective,
    NewsSourceComponent,
    EventDetailsComponent,
    EventsComponent,
    StringContainsFilterPipe,
    HomepageComponent,
    DialogComponent,
    MediaViewerComponent,
    ChatComponent,
    ChatSplashComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModalModule,
    NgbModule,
    NgxExtendedPdfViewerModule,
    NgSelectModule,
    AuthConfigModule,
    NgOptionHighlightModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }, {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiJsonInterceptor,
      multi: true
    }, {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: UserRoleInterceptor,
    //   multi: true
    // }, {
      provide: 'Window',
      useValue: window
    },
    EnvironmentProvider
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
