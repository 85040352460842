<div class="row fill-height">
<!--
  <div class="col-3 sidebar pl-0 pr-0 fill-height">
    <div class="resource-item pointer" *ngFor="let resource of resources" (click)="onResourceSelection(resource)">
      <div class="resource-selected" [ngClass]="{'selected': isSelected(resource)}">&nbsp;</div>
      <div class="resource-icon text-center">
        <img src="{{resource.iconUrl}}" onerror="this.style.display='none'" width="32" height="32"/>
      </div>
      <div class="resource-body">
        <a class="nav-link" href="javascript:void(0)">{{resource.displayName}}</a>
      </div>
    </div>
  </div>
  -->

  <div class="col-9 pl-0 pr-0 fill-height">
    <router-outlet></router-outlet>
  </div>
</div>
