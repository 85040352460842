import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http'
import {Environment} from '../app.environment'
import {Observable} from 'rxjs/internal/Observable'
import {Events} from '../components/events/events'
import {Event} from '../components/events/event'


@Injectable({
  providedIn: 'root'
})
export class EventsService {
  constructor(private httpClient: HttpClient, private environment: Environment) {
  }

  getEvents(): Observable<Events> {
    return this.httpClient.get<Events>(this.environment.getEventsUrl())
  }

  getEventForId(eventId: number): Observable<Event> {
    return this.httpClient.get<Event>(this.environment.getEventsUrl() + '/' + eventId)
  }
}
