import {Component, Input, OnInit} from '@angular/core'
import {News} from '../news';
import {DomSanitizer} from '@angular/platform-browser';
import {ActivityLogService} from "../../../services/activity-log.service";

@Component({
  selector: 'app-news-detail',
  templateUrl: './news-detail.component.html',
  styleUrls: ['./news-detail.component.scss'],
})
export class NewsDetailComponent implements OnInit {
  @Input() news: News

  constructor(private sanitizer: DomSanitizer,
              private activityLogService: ActivityLogService) {
  }

  ngOnInit() {
    this.activityLogService.log('Whats New Details')
  }

  sanitizeVideoUrl() {
    return this.sanitizer.bypassSecurityTrustHtml(this.news.embeddedVideoHtml)
  }

  showVideo(): boolean {
    if (this.news.embeddedVideoHtml && this.news.embeddedVideoHtml !== '') {
      return true
    }
    return false

  }

  onAttachment() {
    this.activityLogService.log('News Attachment')
    window.open(this.news.attachmentUrl)
  }

}
