import {Component, DoCheck, OnInit} from '@angular/core'
import {LocalStorageService} from './services/local-storage.service'
import {AuthorizationService} from './services/authorization.service'
import {Router} from '@angular/router'
import {HttpClient} from '@angular/common/http'
import {Environment} from './app.environment'
import {MoreConfiguration} from '../models/more-configuration'
import {MoreConfigurationService} from './services/more-configuration.service'
import {RESOURCE_SITE} from './components/resources/RESOURCE_SITE.enum'
import {OidcSecurityService} from 'angular-auth-oidc-client'
import {RoleService} from './services/role.service'
import {ActivityLogService} from './services/activity-log.service'
import {TokenUtil} from './util/token.util'
import {DialogService} from './services/dialog.service'

const {version: appVersion} = require('../../package.json')

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [LocalStorageService]
})
export class AppComponent implements OnInit, DoCheck {

  appVersion: any
  moreConfiguration: MoreConfiguration
  siteTypes = RESOURCE_SITE

  private authCheck: any

  constructor(
    private router: Router,
    private httpClient: HttpClient,
    private environment: Environment,
    private localStorageService: LocalStorageService,
    private auth: AuthorizationService,
    private moreConfigurationService: MoreConfigurationService,
    private activityLogService: ActivityLogService,
    private oidcSecurityService: OidcSecurityService,
    private roleService: RoleService,
    private dialog: DialogService) {
    this.appVersion = appVersion
  }

  ngOnInit() {
    this.oidcSecurityService.checkAuth().subscribe({
      next: response => {
        if (!response.isAuthenticated) {
          this.localStorageService.clear()
        } else {
          this.oidcSecurityService.getAccessToken().subscribe(accessToken => {
            const vumcId = TokenUtil.getUserIdFromToken(accessToken)

            if (accessToken && vumcId) {
              this.localStorageService.setToken(accessToken)
              this.localStorageService.setUserId(vumcId)
              this.activityLogService.log('User Authenticated')

              this.roleService.updateCurrentUserRoles()
              this.moreConfigurationService.getMoreConfiguration().subscribe(moreConfig => {
                  this.moreConfiguration = moreConfig
                }
              )
            } else if (accessToken && !vumcId) {
              this.dialog.openInfoDialogWithCallBack('Unauthorized Login',
                'Please make sure you are logged in with a VUMC account.',
                null,
                'Ok',
                null)
                .subscribe(confirmed => {
                  this.onLogout()
                })
            }
          })
        }
      }
    })
  }

  ngDoCheck(): void {
    /*
    * check source of truth to see if user is actually authenticated.
    * only check if isAuthenticated is false to prevent checking on login page.
    */
    if (this.isAuthenticated()) {
      if (!!this.authCheck) {
        this.authCheck.unsubscribe()
      }
      this.authCheck = this.oidcSecurityService.isAuthenticated().subscribe({
        next: (isAuthenticated) => {
          if (!isAuthenticated) {
            this.localStorageService.clear()
          }
        }
      })
    }
  }

  isAuthenticated() {
    return !!this.localStorageService.getToken()
  }

  shouldShowRouterOutlet() {
    return this.isAuthenticated() || this.router.url === '/login'
  }

  onResources(siteId: string) {
    this.activityLogService.log('Resources' + siteId)
  }

  onEvents() {
    this.activityLogService.log('Events - Top Nav')
  }

  onFeedback() {
    window.open(this.moreConfiguration.feedbackUrl)
  }

  onCreativeCommons() {
    this.router.navigate(['/creative-commons'])
  }

  onLogout() {
    this.auth.logout()
    this.router.navigate(['/login'])
  }

  shouldShowFeedback() {
    return this.moreConfiguration != null
  }

  shouldShowEvents(): boolean {
    return this.roleService.hasEventsRole()
  }
}
