import {Directive, ElementRef, Input, OnInit} from '@angular/core'

@Directive({
  selector: '[appAutofocus]'
})
export class AutofocusDirective implements OnInit {

  @Input('appAutofocus') isFocused: boolean

  constructor(private hostElement: ElementRef) {
  }

  ngOnInit() {
    if (this.isFocused) {
      this.hostElement.nativeElement.focus()
    }
  }
}
