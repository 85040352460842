import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core'
import {SharepointFile} from '../../../../../models/sharepoint-file'
import {SharepointService} from '../../../../services/sharepoint-service'
import {LocalStorageService} from '../../../../services/local-storage.service'
import {NgxExtendedPdfViewerService} from 'ngx-extended-pdf-viewer'
import {OidcSecurityService} from 'angular-auth-oidc-client'

@Component({
  selector: 'app-media-viewer',
  templateUrl: './media-viewer.component.html',
  styleUrls: ['./media-viewer.component.scss']
})
export class MediaViewerComponent implements OnChanges {

  public mediaTypeAudio = 'audio'
  public mediaTypeVideo = 'video'
  public mediaTypeImage = 'image'
  public mediaTypePdf = 'pdf'

  @Input() file: SharepointFile
  @Input() useFileUrl = false
  @Output() onFavoriteClicked: EventEmitter<boolean> = new EventEmitter<boolean>()
  @Output() onFileShared: EventEmitter<string> = new EventEmitter<string>()

  type = ''
  fileUrl = ''
  authHeader = {Authorization: ''}
  shareSuccessToast: string
  downloadClicked: boolean

  constructor(private sharepointService: SharepointService,
              private localStorageService: LocalStorageService,
              private pdfViewerService: NgxExtendedPdfViewerService,
              private oidcSecurityService: OidcSecurityService) {

  }

  ngOnChanges(changes: SimpleChanges) {
    this.updateComponentValues()
  }

  private updateComponentValues() {
    window.scrollTo(0, 0)
    this.oidcSecurityService.getAccessToken().subscribe(accessToken => {
      if (!this.useFileUrl) {
        const fileType = atob(this.file.id).split('.').pop() ?? ''
        this.type = this.getMediaType(fileType)

        this.fileUrl = this.sharepointService.getSharepointUrl(this.file.siteId, this.file.id)
          + `?bearer=${accessToken}`
      } else {
        this.fileUrl = this.file.url
        this.type = this.file.extension
      }

      this.authHeader = {Authorization: `Bearer ${accessToken}`}
    })
  }

  getMediaType(fileType: string): string {
    switch (fileType) {
      case 'mp3':
        return this.mediaTypeAudio
      case 'mp4':
        return this.mediaTypeVideo
      case 'jpg':
      case 'jpeg':
      case 'png':
        return this.mediaTypeImage
      case 'pdf':
        return this.mediaTypePdf
      default:
        return ''
    }
  }

  onFavoriteClick() {
    this.onFavoriteClicked.emit(this.file?.favorite)
  }

  getFavoriteIconSvg(): string {
    const fillColor = this.file?.favorite ? '#1F7DCF' : 'currentColor'
    if(this.file.favorite) {
      return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" height="22" width="22"><path fill="${fillColor}" d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/></svg>`
    }
    return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" height="22" width="22"><path d="M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM388.6 312.3l23.7 138.4L288 385.4l-124.3 65.3 23.7-138.4-100.6-98 139-20.2 62.2-126 62.2 126 139 20.2-100.6 98z"/></svg>`
  }

  getDownloadIconSvg() {
    const fillColor = this.downloadClicked ? '#1F7DCF' : 'currentColor'
    return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" height="22" width="22"><path fill="${fillColor}" d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm76.5 211.4l-96.4 95.7c-6.7 6.6-17.4 6.6-24 0l-96.4-95.7C73.4 337.3 80.5 320 94.8 320H160v-80c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v80h65.2c14.3 0 21.4 17.3 11.3 27.4zM377 105L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9z"/></svg>`
  }

  getShareIconSvg(): string {
    const fillColor = !!this.shareSuccessToast ? '#1F7DCF' : 'currentColor'
    return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" height="22" width="22"><path fill="${fillColor}" d="M326.6 185.4c59.7 59.8 58.9 155.7 .4 214.6-.1 .1-.2 .3-.4 .4l-67.2 67.2c-59.3 59.3-155.7 59.3-215 0-59.3-59.3-59.3-155.7 0-215l37.1-37.1c9.8-9.8 26.8-3.3 27.3 10.6 .6 17.7 3.8 35.5 9.7 52.7 2 5.8 .6 12.3-3.8 16.6l-13.1 13.1c-28 28-28.9 73.7-1.2 102 28 28.6 74.1 28.7 102.3 .5l67.2-67.2c28.2-28.2 28.1-73.8 0-101.8-3.7-3.7-7.4-6.6-10.3-8.6a16 16 0 0 1 -6.9-12.6c-.4-10.6 3.3-21.5 11.7-29.8l21.1-21.1c5.5-5.5 14.2-6.2 20.6-1.7a152.5 152.5 0 0 1 20.5 17.2zM467.5 44.4c-59.3-59.3-155.7-59.3-215 0l-67.2 67.2c-.1 .1-.3 .3-.4 .4-58.6 58.9-59.4 154.8 .4 214.6a152.5 152.5 0 0 0 20.5 17.2c6.4 4.5 15.1 3.8 20.6-1.7l21.1-21.1c8.4-8.4 12.1-19.2 11.7-29.8a16 16 0 0 0 -6.9-12.6c-2.9-2-6.6-4.9-10.3-8.6-28.1-28.1-28.2-73.6 0-101.8l67.2-67.2c28.2-28.2 74.3-28.1 102.3 .5 27.8 28.3 26.9 73.9-1.2 102l-13.1 13.1c-4.4 4.4-5.8 10.8-3.8 16.6 5.9 17.2 9 35 9.7 52.7 .5 13.9 17.5 20.4 27.3 10.6l37.1-37.1c59.3-59.3 59.3-155.7 0-215z"/></svg>`
  }

  getOpenInNewTabIconSvg(): string {
    return `<svg xmlns="http://www.w3.org/2000/svg" height="22" width="22" viewBox="0 0 576 512"><path fill="#5a5a5a" d="M432 320H400a16 16 0 0 0 -16 16V448H64V128H208a16 16 0 0 0 16-16V80a16 16 0 0 0 -16-16H48A48 48 0 0 0 0 112V464a48 48 0 0 0 48 48H400a48 48 0 0 0 48-48V336A16 16 0 0 0 432 320zM488 0h-128c-21.4 0-32.1 25.9-17 41l35.7 35.7L135 320.4a24 24 0 0 0 0 34L157.7 377a24 24 0 0 0 34 0L435.3 133.3 471 169c15 15 41 4.5 41-17V24A24 24 0 0 0 488 0z"/></svg>`
  }

  async openFileInNewTab() {
    const blob = await this.pdfViewerService.getCurrentDocumentAsBlob();
    const blobURL = URL.createObjectURL(blob);
    window.open(blobURL);
  }

  shareFile() {
    const url = window.location.href
    // copies
    const selBox = document.createElement('textarea')
    selBox.style.position = 'fixed'
    selBox.style.left = '0'
    selBox.style.top = '0'
    selBox.style.opacity = '0'
    selBox.value = url
    document.body.appendChild(selBox)
    selBox.focus()
    selBox.select()
    document.execCommand('copy')
    document.body.removeChild(selBox)

    this.shareSuccessToast = 'Url copied to clipboard'
    setTimeout(() => {
      this.shareSuccessToast = null
    }, 750)
    this.onFileShared.emit(url)
  }

  async onDownloadClick() {
    this.downloadClicked = true
    setTimeout(() => {
      this.downloadClicked = false
    }, 750)

    const blob = await this.pdfViewerService.getCurrentDocumentAsBlob();
    const blobURL = URL.createObjectURL(blob);
    this.downloadPdf(blobURL)
  }

  downloadPdf(blobURL) {
    const link = document.createElement('a');
    link.setAttribute('type', 'hidden');
    link.href = `${blobURL}`; //path to the file
    link.download = this.file.name;// name that the file takes
    document.body.appendChild(link);
    link.click();
    link.remove();
  }


}
