import {Injectable} from '@angular/core'
import {Environment} from '../app.environment'
import {HttpClient} from '@angular/common/http'
import {Observable} from 'rxjs/internal/Observable'
import {tap} from 'rxjs/operators'
import {LocalStorageService} from './local-storage.service'
import {OidcSecurityService} from 'angular-auth-oidc-client'

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {
  constructor(
    private http: HttpClient, private env: Environment,
    private localStorageService: LocalStorageService,
    private oidcSecurityService: OidcSecurityService
  ) {
  }

  login(username: string, password: string): Observable<string> {
    const request = {
      username,
      password,
      deviceType: 'Web',
      deviceToken: ''
    }
    const options: object = {responseType: 'text'}

    return this.http.post<string>(this.env.getAuthUrl(), request, options)
      .pipe(tap(token => {
        this.localStorageService.setToken(token)
      }))
  }

  logout() {
    this.oidcSecurityService.logoffAndRevokeTokens().subscribe(() => {
      this.localStorageService.clear()
    })
  }
}
