import {Component, OnInit} from '@angular/core'
import {NewsArchiveService} from '../../../services/news-archive.service'
import {NewsSource} from '../../../../models/news-source'
import {News} from '../../news/news'
import {NavigationEnd, Router, RouterEvent} from '@angular/router'
import {filter} from 'rxjs/operators'
import {NewsService} from '../../../services/news.service'
import {DialogService} from '../../../services/dialog.service'
import {ActivityLogService} from "../../../services/activity-log.service";

@Component({
  selector: 'app-news-source',
  templateUrl: './news-source.component.html',
  styleUrls: ['./news-source.component.scss']
})
export class NewsSourceComponent implements OnInit {

  isLoading = false
  hasError = false
  newsSources: NewsSource[] = []
  selectedNewsSource: NewsSource
  breadcrumbs: NewsSource[] = []
  news: News[] = []
  showSources = true
  selectedNewsItem: News
  showingFavorites = false
  myFavorites = 'My Favorites'

  httpRequestSubscription: any

  constructor(private newsArchiveService: NewsArchiveService,
              private activityLogService: ActivityLogService,
              private router: Router,
              private newsService: NewsService,
              private dialog: DialogService) {
  }

  ngOnInit() {
    this.loadTopics()
    // TODO fix error
    // this.router.events.pipe(
    //   filter((event: RouterEvent) => event instanceof NavigationEnd && event.url.startsWith('/resources/news-source'))
    // ).subscribe(() => {
    //   this.loadTopics()
    // })
  }

  private loadTopics() {
    this.showSources = true
    this.news = []
    this.newsSources = []
    this.selectedNewsItem = null
    this.showingFavorites = false

    this.isLoading = true
    this.cancelOtherRequests()
    this.httpRequestSubscription = this.newsArchiveService.getNewsSources().subscribe(response => {
        this.newsSources = response
        this.isLoading = false
      },
      () => {
        this.isLoading = false
        this.hasError = true
        this.dialog.openErrorDialog()
      })
    this.setFirstBreadCrumb()
  }

  private cancelOtherRequests() {
    if (this.httpRequestSubscription) {
      this.httpRequestSubscription.unsubscribe()
    }
  }

  private setFirstBreadCrumb() {
    const firstBreadcrumb = new NewsSource()
    firstBreadcrumb.title = 'Newsfeed Articles'
    this.breadcrumbs = [firstBreadcrumb]
  }

  goToFavorites() {
    this.activityLogService.log('Resource Newsfeed Archive My Favorites')
    this.showFavorites(true)
  }

  private showFavorites(openFirstItem: boolean) {
    this.showSources = false
    this.showingFavorites = true
    this.isLoading = true
    this.selectedNewsItem = null

    this.setFirstBreadCrumb()

    const favoriteFolder = new NewsSource()
    favoriteFolder.title = this.myFavorites
    favoriteFolder.isFavorite = true

    this.breadcrumbs.push(favoriteFolder)

    this.cancelOtherRequests()

    this.httpRequestSubscription = this.newsArchiveService.getArchivedNewsFavorites().subscribe(favorites => {
      this.isLoading = false
      this.news = favorites
      this.autoOpenFirstItem();
    }, () => {
      this.isLoading = false
      this.dialog.openErrorDialog()
    })
  }

  onNewsSourceSelection(newsSource: NewsSource) {
    this.showSources = false
    this.activityLogService.log('Resource News Archive Source')
    this.setNewBreadcrumb(newsSource)
    this.selectedNewsSource = newsSource
    this.loadArchivedNewsForSource(newsSource)
  }

  private loadArchivedNewsForSource(newsSource: NewsSource) {
    this.isLoading = true
    this.newsArchiveService.getArchivedNewsItems(newsSource.id).subscribe(response => {
      this.isLoading = false
      this.news = response
      this.autoOpenFirstItem();
    }, error => {
      this.isLoading = false
      this.dialog.openErrorDialog()
    })
  }

  private autoOpenFirstItem() {
    if (this.news && this.news.length > 0 && !this.news[0].direct) {
      this.onSelectingNewsItem(this.news[0])
    }
  }

  isNewsListEmpty() {
    if (this.news && this.news.length <= 0) {
      return true
    } else {
      return false
    }
  }

  setNewBreadcrumb(newsSource: NewsSource) {
    const lastBreadcrumb = this.breadcrumbs[this.breadcrumbs.length - 1]
    if (lastBreadcrumb.title !== 'Newsfeed Articles') {
      this.breadcrumbs.pop()
    }
    this.breadcrumbs.push(newsSource)
  }

  selectBreadcrumb(index) {
    if (index === 0) {
      this.loadTopics()
    }
  }

  getCurrentFolderName() {
    if (this.showSources) {
      return 'Topics'
    } else {
      return this.breadcrumbs[this.breadcrumbs.length - 1].title
    }
  }

  onSelectingNewsItem(newsItem: News) {
    this.selectedNewsItem = newsItem
    this.activityLogService.log('Resource Newsfeed Archive News')
    if (newsItem.direct) {
      this.activityLogService.log('News Attachment ')
      window.open(newsItem.attachmentUrl)
    }
  }

  toggleFavorite(newsItem: News, event) {
    event.stopPropagation()
    newsItem.favorite = !newsItem.favorite

    this.newsService.updateNewsItemFavorite(newsItem).subscribe(() => {
      if (newsItem.favorite) {
        this.activityLogService.log('Resource Newsfeed Archive News Item Favorite List')
      } else {
        this.activityLogService.log('Resource Newsfeed Archive News Item UnFavorite List')
        if (this.showingFavorites) {
          this.showFavorites(false)
        }
      }
    }, () => {
      this.dialog.openErrorDialog('There was a problem updating your favorites. Please try again.')
      newsItem.favorite = !newsItem.favorite
    })
  }

  isSelected(newsItem: News): boolean {
    return this.selectedNewsItem && this.selectedNewsItem.id === newsItem.id
  }

  getTopicColor() {
    if (!this.showSources && !this.showingFavorites) {
      return this.selectedNewsSource.textColor
    }
  }

  getContentTypeIcon(contentType: string) {
    if (contentType) {
      switch (contentType.toUpperCase()) {
        case 'AUDIO': {
          return 'assets/images/ic_podcast.png'
        }
        case 'VIDEO': {
          return 'assets/images/ic_video.png'
        }
        default: {
          return ''
        }
      }
    }
  }

  getSourceIconUrl(newsItem: News) {
    if (newsItem) {
      if (newsItem.pinned) {
        return 'assets/images/ic_pin.png'
      } else {
        return newsItem.source.iconUrl
      }
    }
  }
}
