<div class="row fill-height pt-3">
  <div class="col-8 fill-height events-container">

    <div class="container-fluid pb-2">
      <div class="input-group mb-4" *ngIf="!isLoading">
        <div class="input-group-prepend">
          <span class="input-group-text"><i class="fa fa-search fa-fw"></i></span>
        </div>
        <input class="form-control" type="search" placeholder="" (ngModelChange)="search()" [(ngModel)]="searchTerm" >
      </div>

      <div class="row">
        <div class="col-2 date-header header-title">Date</div>
        <div class="col-10 font-weight-bold event-card header-title">Event</div>
      </div>
      <div *ngFor="let event of events | StringContainsFilterPipe:{title: searchTerm}; index as i">
        <div *ngIf="event.newYear">
          <div class="row">
            <div class="col-10 offset-2 text-center font-weight-bold pt-5 pb-5">
              {{event.newYear}}
            </div>
          </div>
        </div>
        <div class="row" (click)="showEventDetails(event)">
          <div class="col-2 date-header" [ngClass]="{'pt-4': !event.newYear}">{{getEventDate(i)}}</div>
          <div class="col-10 event-card" [ngClass]="{'pt-4': !event.newYear}">
            <div class="card">
              <div class="card-body" [ngClass]="{'multi-day-event': event.multiDay}">
                <div>{{event.title}}</div>
                <div class="event-detail image-container">
                  <picture>
                    <img src="{{getTimeImage(event)}}">
                  </picture>
                  {{event.time}}
                </div>
                <div class="event-detail image-container" *ngIf="shouldShowLocations(event)">
                  <picture>
                    <img src="{{getLocationImage(event)}}">
                  </picture>
                  {{getLocations(event)}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <div class="col-4 fill-height text-center">
    <img *ngIf="imageUrl" [src]="imageUrl" onerror="this.style.display='none'" class="w-100">
  </div>
</div>
