import {Inject} from '@angular/core';
import {LogLevel} from '@angular/compiler-cli';

export class Environment {
  private readonly baseUrl: string
  private readonly redirectUrl: string
  private readonly secureRoutes: string[]
  private readonly logLevel: LogLevel
  private readonly uiBaseUrl: string

  constructor(hostname: string, @Inject('Window') private window: any) {

    switch (hostname) {
      case 'localhost':
        this.baseUrl = '/vumc2go/api'
        this.uiBaseUrl = `http://${hostname}:4200`
        break
      default:
        this.baseUrl = '/vumc2go/api'
        this.uiBaseUrl = `https://${hostname}`
        break
    }
    this.redirectUrl = this.uiBaseUrl
    this.secureRoutes = [this.baseUrl, this.uiBaseUrl]
  }

  getUnauthorizedUrl(): string {
    return this.uiBaseUrl
  }
  getSecureRoutes(): string[] {
    return this.secureRoutes
  }
  getRedirectUrl() {
    return this.redirectUrl
  }
  getLogLevel(): LogLevel {
    return this.logLevel
  }

  getAuthUrl() {
    return `${this.baseUrl}/auth`
  }

  getResources() {
    return `${this.baseUrl}/resources?v=2`
  }

  getResourceUrl(siteId:string) {
    return `${this.baseUrl}/resources/resource?siteId=${siteId}`
  }

  getPathUrl() {
    return this.window.location.href.replace(this.window.location.hash, '');
  }

  getSharepointFileUrl(siteId: string, fileId: string) {
    return `${this.baseUrl}/sharepoint/sites/${siteId}/files/${fileId}`
  }

  getSharepointViewerUrl(siteId: string, fileUrl: string, token: string) {
    //  pdfSrc = "https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf";
    const url = `${this.getPathUrl()}/web/assets/pdf/web/viewer.html`
      + `?host=${this.getPathUrl()}/web`
      + `&siteId=${siteId}`
      + `&file=${fileUrl}`
      + `&authorization=${token}`;
    return url;
  }

  getUrlRefreshTokenUrl() {
    return `${this.baseUrl}/auth/refreshToken/url`;
  }

  getSharepointDocumentsUrl(siteId: string) {
    return `${this.baseUrl}/sharepoint/sites/${siteId}`
  }

  getSharepointDocumentDetailsUrl(siteId: string, documentId: string) {
    return `${this.baseUrl}/sharepoint/sites/${siteId}/documents/${documentId}`
  }

  getSharepointFavoritesUrl() {
    return `${this.baseUrl}/sharepoint/favorites`
  }

  getSharepointSearchUrl(siteId: string, searchText: string, userId: string) {
    return `${this.baseUrl}/sharepoint/sites/${siteId}/search?q=${searchText}&userId=${userId}`
  }

  getFeedbackResourceUrl() {
    return `${this.baseUrl}/more/configuration`
  }

  getResourceFavorites() {
    return `${this.baseUrl}/sharepoint/favorites`
  }

  getUsersUrl(userId?: string) {
    let url = `${this.baseUrl}/users`
    if (!!userId) {
      url += `/${userId}`
    }

    return url
  }

  getCurrentUserRolesUrl() {
    return this.getUsersUrl() + '/currentUserRoles'
  }

  getNewsSourcesUrl(userId: string) {
    return `${this.getUsersUrl(userId)}/news/archive`
  }

  getArchivedNewsItems(userId: string, sourceId: number) {
    return `${this.getUsersUrl(userId)}/news/archive/${sourceId}`
  }

  getArchivedNewsFavorites(userId: string) {
    return `${this.getUsersUrl(userId)}/news/archiveFavorites`
  }

  updateNewsItemFavorite(userId: string, newsItemId: number, newsItemType: string) {
    return `${this.getUsersUrl(userId)}/news/${newsItemId}/type/${newsItemType}/favorite`
  }

  getEventsUrl() {
    return `${this.baseUrl}/events`
  }

  getHomepageUrl(userId: string) {
    return `${this.baseUrl}/homepage/?userId=${userId}`
  }

  getChatUrl() {
    return `${this.baseUrl}/chat`
  }

  getChatTypeBySiteIdUrl(siteId) {
    return `${this.getChatUrl()}/chatTypes/bySiteId/${siteId}`
  }

  getTermsOfUseUrl(): string {
    return this.baseUrl + '/termsOfUse'
  }

  getTermsOfUseAcknowledgeUrl(version: number): string {
    return this.getTermsOfUseUrl() + `/${version}/acknowledge`
  }
}
