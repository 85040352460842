import {Component, OnInit} from '@angular/core'
import {ResourcesService} from '../../services/resources-service'
import {Resource} from './resource'
import {NavigationEnd, Router, RouterEvent} from '@angular/router'
import {filter} from 'rxjs/operators';
import {HomepageService} from '../../services/homepage.service';
import {Homepage} from '../homepage/homepage';
import {ActivityLogService} from '../../services/activity-log.service';

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss']
})
export class ResourcesComponent implements OnInit {
  resources: Resource[] = []
  isLoading = false
  hasError = false

  selectedResourceId: number
  homepageContent: Homepage

  constructor(private resourcesService: ResourcesService,
              private activityLogService: ActivityLogService, private router: Router,
              private homepageService: HomepageService) {
  }

  ngOnInit() {
    this.isLoading = true
    this.resourcesService.getResources().subscribe(response => {
        this.resources = response
        this.isLoading = false

        const selectableResource = this.getFirstNonUrlResource()
        if (selectableResource) {
          this.onResourceSelection(selectableResource)
        }
      },
      () => {
        this.isLoading = false
        this.hasError = true
      })

    // TODO fix error
    // this.router.events.pipe(
    //   filter((event: RouterEvent, index: number) => event instanceof NavigationEnd && event.url.startsWith('/resources'))
    // ).subscribe(() => {
    //   if (this.router.url) {
    //     const params = this.router.url.split(';');
    //     if (params && params.length > 0) {
    //       const resourceId = params[params.length - 1].split('=')[1];
    //       if (resourceId) {
    //         this.selectedResourceId = Number(resourceId)
    //       }
    //     }
    //   }
    // })

    this.homepageService.getHomepageContent().subscribe(pageContent => {
        this.homepageContent = pageContent
      },
      (err) => {

      })

  }

  getFirstNonUrlResource(): Resource {
    let selectableResource: Resource = null
    if (this.resources.length > 0) {
      for (const resource of this.resources) {
        if (!this.isUrlResource(resource)) {
          selectableResource = resource
          break
        }
      }
    }

    return selectableResource
  }

  onResourceSelection(resource: Resource) {
    this.activityLogService.log('Resources - ' + resource.displayName)

    if (resource.type === 'NEWS_ARCHIVE') {
      this.selectedResourceId = resource.id
      this.activityLogService.log('Resource News Archive')
      this.router.navigate(['/resources/news-source', {
        resourceId: resource.id
      }])
    } else {
      if (this.isUrlResource(resource)) {
        window.open(resource.url)
      } else {
        if (resource.type === 'LINK' && resource.siteId) {
          this.selectedResourceId = resource.id
          this.router.navigate(['/resources/sharepoint', {
            siteId: resource.siteId,
            displayName: resource.displayName,
            resourceId: resource.id
          }])
        }
      }
    }
  }

  private isUrlResource(resource: Resource) {
    return resource.type === 'LINK' && resource.url != null
  }

  isSelected(resource: Resource): boolean {
    return this.selectedResourceId === resource.id
  }
}
