import {Injectable} from '@angular/core'
import {Environment} from '../app.environment'
import {HttpClient} from '@angular/common/http'
import {Observable} from 'rxjs/internal/Observable'
import {Resource} from '../components/resources/resource'

@Injectable({
  providedIn: 'root'
})
export class ResourcesService {

  constructor(private httpClient: HttpClient, private environment: Environment) {
  }

  getResources(): Observable<Resource[]> {
    const url = this.environment.getResources()
    return this.httpClient.get<Resource[]>(url)
  }

  getResource(siteId: string): Observable<Resource> {
    const url = this.environment.getResourceUrl(siteId)
    return this.httpClient.get<Resource>(url)
  }
}
