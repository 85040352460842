import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http'
import {Environment} from '../app.environment'
import {LocalStorageService} from './local-storage.service'
import {Observable} from 'rxjs/internal/Observable'
import {NewsSource} from '../../models/news-source'
import {News} from '../components/news/news';

@Injectable({
  providedIn: 'root'
})
export class NewsArchiveService {

  constructor(private httpClient: HttpClient,
              private environment: Environment,
              private localStorageService: LocalStorageService) { }

  getNewsSources(): Observable<NewsSource[]> {
    const url = this.environment.getNewsSourcesUrl(this.localStorageService.getUserId())
    return this.httpClient.get<NewsSource[]>(url)
  }

  getArchivedNewsItems(sourceId: number): Observable<News[]> {
    const url = this.environment.getArchivedNewsItems(this.localStorageService.getUserId(), sourceId)
    return this.httpClient.get<News[]>(url)
  }

  getArchivedNewsFavorites(): Observable<News[]> {
    const url = this.environment.getArchivedNewsFavorites(this.localStorageService.getUserId())
    return this.httpClient.get<News[]>(url)
  }
}
