<div class="modal-content">
  <div class="modal-header text-center" *ngIf="title">
    <h5 class="modal-title">
      {{title}}
    </h5>
    <button
      type="button"
      class="btn-close float-end"
      data-dismiss="modal"
      aria-label="Close"
      (click)="cancelButtonPressed()">
    </button>
  </div>
  <div class="modal-body">
    <button *ngIf="!title"
            type="button"
            class="btn-close float-end"
            aria-label="Close"
            data-dismiss="modal"
            (click)="cancelButtonPressed()">
    </button>
    <div class="text-center" [ngClass]="{'mt-5': !title}" [innerHtml]="message"></div>
    <div class="mt-5 text-center d-flex button-order">
      <button type="button"
              class="btn btn-outline-primary me-3"
              *ngIf="cancelButtonTitle"
              (click)="cancelButtonPressed()">
        {{cancelButtonTitle}}
      </button>
      <button type="button"
              class="{{actionButtonClass || 'btn-app-primary'}}"
              *ngIf="actionButtonTitle"
              (click)="actionButtonPressed()">
        {{actionButtonTitle}}
      </button>
    </div>
  </div>
</div>
