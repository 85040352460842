<div class="container-fluid fill-height">
  <div class="fill-height d-flex flex-column min-height-0">
    <div class="section-header mt-3 mb-2">
      <div>
        <span *ngFor="let breadcrumb of getBreadcrumbs(); index as i; first as first; last as last; count as count"
              class="d-inline-block me-1">
          <i class="fas align-text-top fa-chevron-right ms-2 me-2" *ngIf="!first"></i>
          <span class="breadcrumb-link"
                [ngClass]="{'breadcrumb-text': (last && !first) || count === 1}"
                (click)="selectBreadcrumb(i)">
            {{breadcrumb.name}}
          </span>
        </span>
      </div>
    </div>

    <div class="flex-grow-1 min-height-0">
      <div class="row fill-height min-height-0">
        <div [ngClass]="getSidebarNavigationClass()">
          <div class="card min-height-0" [ngClass]="{'float-start w-auto': isSidebarCollapsed, 'card-fill-height': !isSidebarCollapsed}">
            <div class="card-header d-flex p-0">
              <div class="base-tab"
                   [ngClass]="{'top-right-radius': !isAIChatAvailable(), 'active': selectedTab === baseTab}"
                   (click)="onTabSelected(baseTab)">{{getBaseTabName()}}</div>
              <div class="ai-chat-tab"
                   [ngClass]="{'active': selectedTab === aiChatTab}"
                   (click)="onTabSelected(aiChatTab)"
                   *ngIf="isAIChatAvailable()">aiChat for Tipsheets</div>
            </div>
            <div class="card-body d-flex flex-column min-height-0">

              <div class="d-none d-lg-block mb-2" *ngIf="!isLoadingDocuments">
                <div class="d-flex justify-content-between pointer mb-3" (click)="toggleSidebarCollapsed()">
                  <div>{{getTabLabel()}}</div>
                  <i class="fa fa-chevron-left align-self-center ms-2" [ngClass]="{'fa-chevron-right': isSidebarCollapsed, 'fa-chevron-left': !isSidebarCollapsed}"></i>
                </div>
              </div>

              <div class="text-center" *ngIf="isLoadingDocuments">
                Loading... <i class="fa fa-spinner fa-spin"></i>
              </div>

              <div class="flex-grow-1 d-flex flex-column min-height-0" *ngIf="selectedTab === baseTab && !isLoadingDocuments && !isSidebarCollapsed">

                <div class="mb-2" *ngIf="shouldShowSearchInput()">
                  <ng-select #fileSearchInput
                             [items]="searchResults.files"
                             bindLabel="name"
                             notFoundText="No results found. Please try a different search criteria."
                             [loadingText]="searchLoadingText"
                             [loading]="searchIsLoading"
                             (clear)="onClearSearchClick()"
                             (close)="onDropDownClosed()"
                             (change)="selectSearchItem(selectedSearchFile)"
                             [(ngModel)]="selectedSearchFile"
                             [typeahead]="fileSearchTypeahead"
                             [placeholder]="getSearchPlaceholder()"
                             [markFirst]="false">

                    <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                      <div class="display-name option-highlight" [ngOptionHighlight]="search">
                        {{item.name}}
                      </div>
                    </ng-template>
                  </ng-select>
                </div>

                <div class="col-scroll min-height-0">
                  <div *ngIf="shouldShowFavoritesFolder()" class="sharepoint-folder section-favorites"
                       (click)="goToFavorites()">
                    <div class="sharepoint-selected">&nbsp;</div>
                    <div class="sharepoint-icon text-center">
                      <i class="far fa-star"></i>
                    </div>
                    <div class="sharepoint-body text-break">Favorites</div>
                  </div>

                  <div class="sharepoint-folder" *ngFor="let folder of folders" (click)="selectFolder(folder)">
                    <div class="sharepoint-selected">&nbsp;</div>
                    <div class="sharepoint-icon text-center">
                      <i class="fas fa-folder"></i>
                    </div>
                    <div class="sharepoint-body text-break">{{folder.name}}</div>
                  </div>
                  <div class="sharepoint-file" *ngFor="let file of files" (click)="openFile(file.id)">
                    <div class="sharepoint-selected" [ngClass]="{'selected': isSelected(file)}">&nbsp;</div>
                    <div class="sharepoint-icon text-center">
                      <i class="far fa-file" *ngIf="file.showNew"></i>
                      <i class="far fa-file" *ngIf="!file.showNew"></i>
                    </div>
                    <div class="sharepoint-body text-break">{{file.name}}</div>
                    <div class="sharepoint-favorite text-center" (click)="toggleFavorite(file, $event, 'List')">
                      <i class="far fa-star" *ngIf="!file.favorite"></i>
                      <i class="fas fa-star" *ngIf="file.favorite"></i>
                    </div>
                  </div>
                  <div class="pl-2 pr-2" *ngIf="isEmpty()">Currently there are no documents available.</div>
                </div>
              </div>

              <div *ngIf="isAIChatActivated()" [ngClass]="getAIChatContentCss()">
                <app-chat [siteId]="siteId" (citationClicked)="onChatCitationClicked($event)"></app-chat>
              </div>

            </div>
          </div>
        </div>
        <div class="justify-content-center col card-fill-height" *ngIf="fileClicked">
          <app-media-viewer [file]="selectedFile"
                            [useFileUrl]="useFileUrl"
                            (onFavoriteClicked)="toggleFavorite(selectedFile, $event, 'File')"
                            (onFileShared)="onFileShared($event)"></app-media-viewer>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- spacer for footer -->
<div *ngIf="showAiChatHeader()" class="mt-3">&nbsp;
  <nav class="navbar fixed-bottom app-footer">
    <div class="d-flex px-2">
      <div class="nav-item me-3">
        <a class="nav-link"
           target="_blank"
           href="https://www.vumc.org/dbmi/GenerativeAI"
           (click)="onAboutClick()">About</a>
      </div>
      <div class="nav-item me-3">
        <a class="nav-link"
           target="_blank"
           href="https://redcap.vumc.org/surveys/?s=WAXAYXENRF8NM3MK"
           (click)="onFeedbackClick()">Feedback</a>
      </div>
      <div class="nav-item">
        <a class="nav-link" href="javascript:void(0)"
           (click)="onTermsClick()">VUMC Terms of Use</a>
      </div>
    </div>
  </nav>

</div>


