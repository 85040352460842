<div class="modal-content">
  <div class="modal-header text-center">
    <h5 class="modal-title">
      VUMC Terms of Use
    </h5>
  </div>
  <div class="modal-body">
    <div class="text-center mt-5" *ngIf="isLoadingTermsOfUse">
      <i class="fa fa-spinner fa-spin fa-1x"></i>
      <div class="mt-2">Loading</div>
    </div>
    <div [innerHtml]="termsOfUse?.body"></div>
    <div class="mt-5 text-center d-flex button-order">
      <button type="button"
              class="btn-app-primary"
              (click)="onAgree()">
        I agree
      </button>
    </div>
  </div>
</div>
