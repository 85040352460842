export class TokenUtil {

  private static readonly KEY_VUMC_ID = 'VUMCID'
  private static readonly KEY_NAME = 'name'

  static getUserIdFromToken(token) {
    const json = this.getTokenBody(token)
    return json[TokenUtil.KEY_VUMC_ID]
  }

  static getUserNameFromToken(token) {
    const json = this.getTokenBody(token)
    return json[TokenUtil.KEY_NAME]
  }

  private static getTokenBody(token) {
    let body: any = {}
    try {
      const encoded = token.split('.')[1]
      body = JSON.parse(atob(encoded))
    } catch (e) {
      //
    }

    return body
  }
}
