import {Environment} from './app.environment';
import {AuthModule, OpenIdConfiguration, StsConfigLoader, StsConfigStaticLoader} from 'angular-auth-oidc-client';
import {NgModule} from '@angular/core';

export const azureConfig = (environment: Environment) => {
  const config = {
    authority: 'https://login.microsoftonline.com/ef575030-1424-4ed8-b83c-12c533d879ab/v2.0',
    redirectUrl: environment.getRedirectUrl(),
    postLoginRoute: '/homepage',
    postLogoutRedirectUri: environment.getUnauthorizedUrl(),
    clientId: '58d1f627-ec8a-4c7b-a2bd-16b81f36fbaa',
    scope: 'openid profile offline_access api://58d1f627-ec8a-4c7b-a2bd-16b81f36fbaa/app',
    responseType: 'code',
    silentRenew: true,
    useRefreshToken: true,
    autoUserInfo: false,
    // unauthorizedRoute: '/redirect',
    // forbiddenRoute: '/redirect',
    logLevel: environment.getLogLevel(),
    maxIdTokenIatOffsetAllowedInSeconds: 1000,
    secureRoutes: environment.getSecureRoutes()
  } as unknown as OpenIdConfiguration

  return new StsConfigStaticLoader(config);
};

@NgModule({
  imports: [ AuthModule.forRoot({
    loader: {
      provide: StsConfigLoader,
      useFactory: azureConfig,
      deps: [ Environment ],
    }
  }) ],
  exports: [ AuthModule ],
})
export class AuthConfigModule { }
