<div class="container-fluid fill-height">
  <div class="row fill-height">
    <div class="col-4 sidebar pl-0 pr-0 fill-height">
      <div class="section-header">
        <div class="ml-2">
          <span *ngFor="let breadcrumb of breadcrumbs; index as i; first as first; last as last"
                class="d-inline-block text-breadcrumb">
            <i class="fas fa-chevron-right ml-2 mr-2 text-breadcrumb" *ngIf="!first"></i>
            <a href="javascript:void(0)"
               [ngClass]="{'font-weight-bold': last && !first}"
               (click)="selectBreadcrumb(i)">
              {{breadcrumb.title}}
            </a>
          </span>
        </div>
        <a href="javascript:void(0)" (click)="goToFavorites()" class="text-breadcrumb mt-2 ml-2">
          <i class="far fa-star text-breadcrumb"></i>
          My Newsfeed Favorites
        </a>
        <div class="current-folder mt-3 pl-2" [ngStyle]="{'color':getTopicColor()}">{{getCurrentFolderName()}}</div>
      </div>

      <div class="text-center" *ngIf="isLoading">
        Loading... <i class="fa fa-spinner fa-spin"></i>
      </div>

      <div class="text-center" *ngIf="!isLoading && isNewsListEmpty() && !showSources">
        Currently we do not have any news items.
      </div>

      <div *ngIf="!isLoading">

        <div class="news-source" *ngIf="showSources">
          <div class="news-source-item pointer" *ngFor="let source of newsSources"
               (click)="onNewsSourceSelection(source)">
            <div class="news-source-icon">
              <img class="source-icon" src="{{source.iconUrl}}" onerror="this.style.display='none'"/>
            </div>
            <div class="news-source-body">
              <a class="nav-link" href="javascript:void(0)" [ngStyle]="{'color':source.textColor}">{{source.title}}</a>
            </div>
          </div>
        </div>

        <div class="item" *ngIf="!showSources && !isNewsListEmpty()">
          <div *ngFor="let newsItem of news">
            <div [ngStyle]="{'background-color': newsItem.backgroundColor}">
              <div class="news-item" (click)="onSelectingNewsItem(newsItem)">

                <div class="news-item-selected" [ngClass]="{'selected': isSelected(newsItem)}"></div>

                <div class="news-item-icon">
                  <img class="news-source-icon-url" src="{{getSourceIconUrl(newsItem)}}"
                       onerror="this.style.display='none'"/>
                </div>

                <div class="news-item-body text-break">{{newsItem.title}}</div>

                <div class="news-item-favorite" (click)="toggleFavorite(newsItem, $event)">
                  <i class="far fa-star" *ngIf="!newsItem.favorite"></i>
                  <i class="fas fa-star" *ngIf="newsItem.favorite"></i>
                </div>

                <div class="news-item-selected" [ngClass]="{'selected': isSelected(newsItem)}"></div>
                <div class="news-date-divider">
                </div>
                <div class="news-item-body text-break news-date">{{newsItem.startDate| date: 'MMM d, yyyy'}}</div>

                <div class="news-item-icon">
                  <img class="content-type-icon" src="{{getContentTypeIcon(newsItem.contentType)}}"
                       onerror="this.style.display='none'"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-8 sidebar pl-0 pr-0 fill-height">

      <div class="fill-height d-flex" *ngIf="selectedNewsItem && selectedNewsItem.direct">
        <div class="align-self-center w-100 text-center article-message">
          Article will open in a new tab.
        </div>
      </div>

      <div class="fill-height" *ngIf="selectedNewsItem && !selectedNewsItem.direct">
        <app-news-detail
          [news]="selectedNewsItem">
        </app-news-detail>
      </div>

    </div>
  </div>
</div>
