<div class="d-flex justify-content-center homepage-container">


  <div class="link-container">
    <div class="flex-column homepage-square hubbl" (click)="onResourceSelection(siteTypes.HUBBL)">
      <div class="logo">
        <img src="assets/images/hubbl-icon.svg" alt="vumc2go" width="65" height="65">
      </div>
      <div class="square-title mt-2">
        <p class="m-0 p-0">
          <span class="hubbl-text h4">Hubbl</span>
          <br/>
          eStar Tip Sheets
        </p>
        +
        <img class="m-0 p-0" src="assets/images/aichat-log-white.png" alt="vumc2go" width="45" height="20">
      </div>
    </div>

    <div class="flex-column homepage-square covid" (click)="onResourceSelection(siteTypes.COVID)">
      <div class="logo">
        <img src="assets/covidlogo.png" alt="vumc2go" width="65" height="65">
      </div>
      <div class="square-title mt-2">
        <p>
          COVID-19<br/>
          Resources
        </p>
      </div>
    </div>
  </div>
</div>



