import {Component, OnInit} from '@angular/core'
import {EventsService} from '../../services/events.service'
import {Event} from './event'
import {Router} from '@angular/router';
import {DialogService} from '../../services/dialog.service'
import {ActivityLogService} from "../../services/activity-log.service";

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {

  imageUrl: string
  events: Event[]
  isLoading = false
  hasError = false
  searchTerm: string


  constructor(private eventsService: EventsService,
              private dialog: DialogService,
              private activityLogService: ActivityLogService,
              private router: Router) {
  }

  ngOnInit() {
    this.loadEvents()
  }

  private loadEvents() {
    this.events = []
    this.imageUrl = null

    this.isLoading = true
    this.eventsService.getEvents().subscribe(response => {
        this.events = response.events
        this.imageUrl = response.heroImageUrl
        this.isLoading = false
      },
      () => {
        this.isLoading = false
        this.hasError = true
        this.dialog.openErrorDialog()
      })
  }

  getEventDate(index): string {
    if (index === 0) {
      return this.events[0].date
    }

    const previousEventDate = this.events[index - 1].date
    const currentEventDate = this.events[index].date
    return previousEventDate === currentEventDate ? '' : currentEventDate
  }

  getLocations(event: Event): string {
    if (event && event.locations) {
      let locationStr = ''
      let separator = ''
      for (const location of event.locations) {
        locationStr += separator + location.name
        separator = ', '
      }
      return locationStr
    }

    return ''
  }

  showEventDetails(event: Event) {
    this.activityLogService.log('Events - Tile')
    if (this.hasUrl(event) && !this.hasDescription(event)) {
      window.open(event.url)
    } else {
      this.router.navigateByUrl(`events/${event.id}`)
    }
  }

  hasUrl(event: Event) {
    return event.url && event.url != null && event.url.trim() !== ''
  }

  hasDescription(event: Event) {
    return event.description && event.description != null && event.description.trim() !== ''
  }

  shouldShowLocations(event: Event) {
    return event.locations && event.locations.length > 0
  }

  getTimeImage(event: Event) {
    if (event.multiDay) {
      return 'assets/images/time_teal.png'
    } else {
      return 'assets/images/time.png'
    }
  }

  getLocationImage(event: Event) {
    if (event.multiDay) {
      return 'assets/images/location_teal.png'
    } else {
      return 'assets/images/location.png'
    }
  }

  search() {
    if (this.searchTerm && this.searchTerm !== '') {
      this.activityLogService.log('Events - Search')
    } else {
      this.activityLogService.log('Events - Search Clear')
    }
  }
}
