import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-creative-commons',
  templateUrl: './creative-commons.component.html',
  styleUrls: ['./creative-commons.component.scss']
})
export class CreativeCommonsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
