import {Component, OnInit} from '@angular/core'
import {Event} from '../event';
import {Location} from '../location';
import {EventsService} from '../../../services/events.service';
import {ActivatedRoute, Router} from '@angular/router';
import {LocationMapUtil} from '../../../util/location-map-util';
import {DialogService} from '../../../services/dialog.service'
import {ActivityLogService} from "../../../services/activity-log.service";

@Component({
  selector: 'app-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.scss'],
})
export class EventDetailsComponent implements OnInit {
  eventCal: Event
  isLoading = false
  hasError = false

  constructor(private activityLogService: ActivityLogService,
              private eventsService: EventsService,
              private dialog: DialogService,
              private router: Router,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.loadEventDetails();
  }

  private loadEventDetails() {
    const eventId = this.route.snapshot.params.eventId
    this.isLoading = true
    this.eventsService.getEventForId(eventId).subscribe(response => {
      this.eventCal = response
      this.isLoading = false
    }, error2 => {
      this.isLoading = false
      this.hasError = true
      this.dialog.openErrorDialog()
    })
  }

  onViewMore(url: string) {
    this.activityLogService.log('Events - View More')
    if (url) {
      window.open(url)
    }
  }

  onAddToCalendar(eventCal: Event) {

  }

  getEventDate() {
    if (this.eventCal) {
      return this.eventCal.fullDateTime.substring(0, this.eventCal.fullDateTime.indexOf('\n'))
    }
  }

  getEventTime() {
    if (this.eventCal) {
      return (this.eventCal.fullDateTime.substring(this.eventCal.fullDateTime.indexOf('\n'),
        this.eventCal.fullDateTime.length)).replace('\n', '')
    }
  }

  getAddressToDisplay(location: Location) {
    let address = ''
    if (location) {
      if (location.name) {
        address = address.concat(location.name)
      }

      if (location.addressLine1) {
        if (location.name && location.name != null && location.name.trim() !== '') {
          address = address.concat(', ')
        }
        address = address.concat(location.addressLine1)
      }
      if (location.addressLine2) {
        address = address.concat(', ').concat(location.addressLine2)
      }
      if (location.city) {
        address = address.concat(', ').concat(location.city)
      }

      if (location.state) {
        address = address.concat(', ').concat(location.state)
      }

      if (location.zip) {
        address = address.concat(' - ').concat(location.zip)
      }
      return address

    }

  }

  hasUrl(event: Event) {
    return event.url && event.url != null && event.url.trim() !== ''
  }

  hasDescription(event: Event) {
    return event.description && event.description != null && event.description.trim() !== ''
  }

  onLocation(location: Location) {
    this.activityLogService.log('Events - Click Location')
    window.open(LocationMapUtil.getMapsLink(location))
  }

}
