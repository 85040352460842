import {Injectable} from '@angular/core'
import {Environment} from '../app.environment'
import {HttpClient} from '@angular/common/http'
import {Observable} from 'rxjs/internal/Observable'
import {SharepointDocuments} from '../../models/sharepoint-documents'
import {SharepointFile} from '../../models/sharepoint-file'
import {SharepointSearch} from "../components/resources/sharepoint/SharepointSearch";

@Injectable({
  providedIn: 'root'
})
export class SharepointService {

  private readonly bodyKey = 'body'

  constructor(private httpClient: HttpClient, private env: Environment) {
  }

  getSharepointUrl(siteId: string, fileId: string): string {
    return this.env.getSharepointFileUrl(siteId, fileId)
  }

  getViewerUrl(siteId: string, token: string, fileUrl: string) {
    return this.env.getSharepointViewerUrl(siteId, fileUrl, token);
  }

  getRefreshToken() {
    return this.httpClient.get<any>(this.env.getUrlRefreshTokenUrl());
  }

  getDocuments(siteId: string, userId: string): Observable<SharepointDocuments> {
    const url = this.env.getSharepointDocumentsUrl(siteId) + '?userId=' + userId // TODO query param
    return this.httpClient.get<SharepointDocuments>(url)
  }

  getDocumentDetails(siteId: string, documentId: string, userId: string): Observable<SharepointDocuments> {
    const url = this.env.getSharepointDocumentDetailsUrl(siteId, documentId) + '?userId=' + userId // TODO query param
    return this.httpClient.get<SharepointDocuments>(url)
  }

  addFavorite(file: SharepointFile, userId: string): Observable<any> {
    const url = this.env.getSharepointFavoritesUrl() + '?userId=' + userId // TODO query param
    const body = JSON.stringify(file)
    return this.httpClient.post(url, body)
  }

  removeFavorite(file: SharepointFile): Observable<any> {
    const url = this.env.getSharepointFavoritesUrl()

    const requestOptions = {}
    requestOptions[this.bodyKey] = JSON.stringify(file)

    return this.httpClient.delete(url, requestOptions)
  }

  getFavorites(siteId: string, userId: string): Observable<SharepointFile[]> {
    const url = this.env.getResourceFavorites() + '?userId=' + userId + '&siteId=' + siteId
    return this.httpClient.get<SharepointFile[]>(url)
  }

  searchDocuments(siteId: string, searchText: string, userId:string): Observable<SharepointSearch> {
    const url = this.env.getSharepointSearchUrl(siteId, searchText, userId)
    return this.httpClient.get<SharepointSearch>(url)
  }

}
