<div class="container">
  <div class="row justify-content-center align-items-center">

    <div class="col-4">
      <div class="form-login text-center">
        <img src="assets/vumc2go_logo_long.png" alt="vumc2go" class="mb-2">
        <div class="mb-2" *ngIf="isSessionExpired">Your session has expired. Please login again.</div>
        <button class="btn btn-lg btn-login btn-block mt-3" type="submit" (click)="login()">
          Login
        </button>
      </div>
    </div>
  </div>
</div>
