<div class="content-section  mb-2">
  <div class="row icon-section" *ngIf="type === mediaTypeAudio">
    <div class="col-12 icon-class text-center">
      <i class="fas fa-headphones fa-2x"></i>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <video *ngIf="type === mediaTypeVideo"
             id="player"
             width="100%"
             height="auto"
             autoplay="autoplay"
             controls class="w-75">
        <source [attr.src]="fileUrl" type="video/mp4">
      </video>
      <audio *ngIf="type === mediaTypeAudio" [attr.src]="fileUrl" preload controls
             class="w-75"></audio>
      <ngx-extended-pdf-viewer
        *ngIf="type === mediaTypePdf"
        [src]="fileUrl"
        [httpHeaders]="authHeader"
        [handTool]="false"
        [zoom]="100"
        [customToolbar]="additionalButtons"></ngx-extended-pdf-viewer>

      <ng-template #additionalButtons>
        <div id="toolbarViewer">
          <div id="toolbarViewerLeft">
            <pdf-toggle-sidebar></pdf-toggle-sidebar>
            <div class="toolbarButtonSpacer"></div>
            <pdf-find-button [showFindButton]="true" [textLayer]="true"></pdf-find-button>
            <pdf-paging-area></pdf-paging-area>
          </div>
          <pdf-zoom-toolbar></pdf-zoom-toolbar> <!-- toolbar viewer middle -->
          <div id="toolbarViewerRight">
            <pdf-shy-button
              [cssClass]="'lg' | responsiveCSSClass"
              title="Open In New Tab"
              primaryToolbarId="pdfTab"
              (click)="openFileInNewTab()"
              [order]="1"
              [image]="getOpenInNewTabIconSvg()"
              [ngbPopover]="shareSuccessToast"
              [closeDelay]="750"
              triggers="click"></pdf-shy-button>
            <pdf-print></pdf-print>
            <!--
            <pdf-download></pdf-download>
            -->
            <pdf-shy-button
              [cssClass]="'lg' | responsiveCSSClass"
              title="Download"
              primaryToolbarId="pdfDownload"
              (click)="onDownloadClick()"
              [order]="2"
              [image]="getDownloadIconSvg()"></pdf-shy-button>
            <pdf-shy-button
              [cssClass]="'lg' | responsiveCSSClass"
              title="Favorite"
              primaryToolbarId="pdfFavorite"
              (click)="onFavoriteClick()"
              [order]="3"
              [image]="getFavoriteIconSvg()"></pdf-shy-button>
            <pdf-shy-button
              [cssClass]="'lg' | responsiveCSSClass"
              title="Copy Url"
              primaryToolbarId="pdfShare"
              (click)="shareFile()"
              [order]="4"
              [image]="getShareIconSvg()"
              [ngbPopover]="shareSuccessToast"
              [closeDelay]="750"
              triggers="click"></pdf-shy-button>
            <div class="verticalToolbarSeparator hiddenSmallView"></div>
            <pdf-toggle-secondary-toolbar></pdf-toggle-secondary-toolbar>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
