import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core'
import {ActivatedRoute, Router, UrlSegment} from '@angular/router'
import {SharepointFolder} from '../../../../models/sharepoint-folder'
import {LocalStorageService} from '../../../services/local-storage.service'
import {SharepointService} from '../../../services/sharepoint-service'
import {SharepointFile} from '../../../../models/sharepoint-file'
import {NgSelectComponent} from '@ng-select/ng-select'
import {Subject} from 'rxjs'
import {SharepointDocuments} from '../../../../models/sharepoint-documents'
import {SharepointSearch} from './SharepointSearch'
import {Location} from '@angular/common'
import {DialogService} from '../../../services/dialog.service'
import {ResourcesService} from '../../../services/resources-service'
import {ActivityLogService} from '../../../services/activity-log.service'
import {RESOURCE_SITE} from '../RESOURCE_SITE.enum'
import {ContentSource} from '../../chat/content-source'
import {ChatSplashComponent} from '../../chat/chat-splash/chat-splash.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ChatService} from '../../../services/chat.service';
import {TermsOfUse} from '../../chat/chat-splash/terms-of-use';

@Component({
  selector: 'app-sharepoint',
  templateUrl: './sharepoint.component.html',
  styleUrls: ['./sharepoint.component.scss'],
  providers: [SharepointService]
})
export class SharepointComponent implements OnInit, OnDestroy {
  public readonly baseTab = 'tab-base'
  public readonly aiChatTab = 'tab-aichat'

  displayName: string
  siteId: string
  fileId: string
  private paramSubscription: any

  breadcrumbsBaseTab: any[] = []
  breadcrumbsAiChatTab: any[] = []
  private readonly pathSeparator = '||'
  isLoadingDocuments = false
  folders: SharepointFolder[] = []
  currentFolder: SharepointFolder
  files: SharepointFile[] = []
  favorites: SharepointFile[] = []
  // for in folder search for future
  filteredFiles: SharepointFile[] = []
  selectedTab = this.baseTab
  aiChatTabActivated = false

  fileClicked = false
  useFileUrl = false
  isSidebarCollapsed = false

  selectedFile: SharepointFile
  showingFavorites = false
  myFavorites = 'My Favorites'

  httpRequestSubscription: any

  readonly searchPlaceholder = 'Search'
  readonly searchLoadingText = 'Loading... Please wait.'
  readonly MIN_CHARS_TO_SEARCH = 2

  searchText: string
  searchIsLoading: boolean
  searchErrorMessage: string
  searchResults: SharepointDocuments = new SharepointDocuments()
  sharePointSearch: SharepointSearch = new SharepointSearch()
  selectedSearchFile: SharepointFile

  authHeader: { 'Authorization': string } = {Authorization: ''}

  @ViewChild('fileSearchInput', {static: true}) ngSelectComponent: NgSelectComponent
  fileSearchTypeahead = new Subject<string | null>()
  private fileSearchSubscription: any
  showFooterList = ['train']
  termsOfUse: TermsOfUse

  constructor(private router: Router,
              private route: ActivatedRoute,
              private sharepointService: SharepointService,
              private resourcesService: ResourcesService,
              private localStorageService: LocalStorageService,
              private activityLogService: ActivityLogService,
              private modal: NgbModal,
              private chatService: ChatService,
              private location: Location,
              private dialog: DialogService) {
    this.fileSearchTypeahead.subscribe((newTerm) => {
        if (newTerm && newTerm.trim().length >= this.MIN_CHARS_TO_SEARCH) {
          this.logEvent('Search', {query: newTerm})
          this.performSearch(newTerm)
        } else {
          this.clearItemsFromList()
        }
      }
    )


    this.paramSubscription = this.route.params.subscribe(params => {
      this.siteId = params.siteId
      this.displayName = params.displayName
      this.fileId = params.fileId
      if (!!this.siteId && !this.displayName) {
        this.resourcesService.getResource(this.siteId).subscribe({
          next: (resource) => {
            const navigateParams: any = {
              siteId: resource.siteId,
              displayName: resource.displayName
            }

            if (!!params.folderPath) {
              navigateParams.folderPath = params.folderPath
            }

            if (!!params.fileId) {
              navigateParams.fileId = encodeURIComponent(params.fileId)
            }

            this.router.navigate(['/sharepoint/' + this.siteId, navigateParams])
          }
        })
      }
      this.logEvent('Initial Load')
      this.loadData(params.fileId, params.folderPath)
    })
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    if (this.paramSubscription) {
      this.paramSubscription.unsubscribe()
    }
  }

  private resetData() {
    this.breadcrumbsBaseTab = []
    this.breadcrumbsAiChatTab = []
    this.folders = []
    this.files = []
    this.currentFolder = null
    this.selectedFile = null
    this.showingFavorites = false
    this.selectedTab = this.baseTab
    this.aiChatTabActivated = false
    this.loadStandaloneFavorites()
  }

  private loadData(fileId: string, folderPath: string): void {
    this.resetData()

    this.resetFileClicked()

    if (this.isAIChatAvailable()) {
      this.loadTermsOfUse()
    }

    // reset search
    this.clearItemsFromList()
    this.clearSearch()
    this.setFirstBreadCrumb()

    // get files and folders
    this.isLoadingDocuments = true
    this.cancelOtherRequests()

    if (folderPath) {
      // go to folder
      const folders = folderPath.split(this.pathSeparator)
      this.goToFolders(folders, 0)
    } else {
      this.httpRequestSubscription = this.sharepointService.getDocuments(this.siteId, this.localStorageService.getUserId())
        .subscribe(documents => {
          this.isLoadingDocuments = false
          this.folders = [].concat(documents.folders)
          this.files = [].concat(documents.files)
          if (fileId) {
            // shareable file
            this.toggleSidebarCollapsed()
            this.openFile(fileId)
          }
        }, () => {
          this.isLoadingDocuments = false
          this.dialog.openErrorDialog()
        })
    }

    this.loadStandaloneFavorites()
  }

  private loadTermsOfUse() {
    this.chatService.getTermsOfUse().subscribe({
      next: termsOfUse => {
        this.termsOfUse = termsOfUse
        if (!this.termsOfUse.acknowledged) {
          this.showTermsOfUseDialog()
        } else {
          // this.focusOnPrompt()
          // this.currentForm.enable()
        }
      },
      error: () => {
        this.showTermsOfUseDialog()
      }
    })
  }

  /*
   Recursive function that will walk through the query param folderPath and go into each folder
   FolderNames - [] of strings that are the folder names to grab that folder ex: ECMO, Forms
   Index - Starts at 0 and loops through folderNames[]
   */
  goToFolders(folderNames, index) {
    let folderFound = new SharepointFolder()
    // if 0 then we need to load the init page Covid-19 or Estar
    if (index === 0) {
      this.isLoadingDocuments = true
      this.httpRequestSubscription = this.sharepointService.getDocuments(this.siteId, this.localStorageService.getUserId())
      .subscribe(documents => {
        this.isLoadingDocuments = false
        this.folders = [].concat(documents.folders)
        this.files = [].concat(documents.files)
        // Find the first folder of the path and select that folder
        folderFound = Object.assign(new SharepointFolder(), this.folders.find(f => f.name === folderNames[index]))
        this.currentFolder = folderFound
        this.selectFolder(folderFound, true)
        this.goToFolders(folderNames, index + 1)
      }, () => {
        this.isLoadingDocuments = false
        this.dialog.openErrorDialog()
      })
    } else {
      // index > 0 grab the folders and files inside current folder(folder found)
      this.isLoadingDocuments = true
      this.httpRequestSubscription =
        this.sharepointService.getDocumentDetails(this.siteId, this.currentFolder.id, this.localStorageService.getUserId()).subscribe(
          documents => {
            this.isLoadingDocuments = false
            this.folders = [].concat(documents.folders)
            this.files = [].concat(documents.files)
            // Loop is at last folder, check if file was on shareable link - open it
            if (index === folderNames.length && this.fileId) {
              this.openFile(this.fileId)
              this.toggleSidebarCollapsed()
            } else {
              // Not on last folder find the next folder and increase index by 1 to keep stepping through path
              folderFound = Object.assign(new SharepointFolder(), this.folders.find(f => f.name === folderNames[index]))
              this.currentFolder = folderFound
              this.selectFolder(folderFound, true)
              this.goToFolders(folderNames, index + 1)
            }
          }, () => {
            this.isLoadingDocuments = false
            this.dialog.openErrorDialog()
          })
    }
  }

  private cancelOtherRequests() {
    if (this.httpRequestSubscription) {
      this.httpRequestSubscription.unsubscribe()
    }
  }

  getSidebarNavigationClass() {
    let cssClass = this.isSidebarCollapsed ? 'col-auto ' : 'col-12 col-lg-4 '
    cssClass += ' fill-height min-height-0 '

    if (this.selectedFile) {
      cssClass += ' d-none d-lg-block'
    }

    return cssClass
  }

  toggleSidebarCollapsed() {
    this.isSidebarCollapsed = !this.isSidebarCollapsed
    this.logEvent(`Sidebar ${this.isSidebarCollapsed ? 'Collapse' : 'Expand'}`)
  }

  selectSearchItem(file: SharepointFile) {
    if (file) {
      this.openFileFromSearch(file.id, file.path)
    }
    this.clearItemsFromList()
  }

  onClearSearchClick() {
    this.logEvent('Search Clear')
    this.clearSearch()
  }

  private clearSearch() {
    this.clearItemsFromList()
    this.selectedSearchFile = null
  }

  private clearItemsFromList() {
    this.searchResults = new SharepointDocuments()
    this.cancelOtherSearchRequests()
  }

  private cancelOtherSearchRequests() {
    if (this.fileSearchSubscription) {
      this.fileSearchSubscription.unsubscribe()
    }
    this.searchIsLoading = false
  }

  onDropDownClosed() {
    this.clearItemsFromList()
  }

  performSearch(searchText: string) {

    // This can be used for searching current folder user is in (future req)
    // this.filteredFiles = this.files.filter(f => f.name.includes(searchText))

    this.searchText = searchText
    this.selectedSearchFile = null
    this.searchErrorMessage = ''

    if (this.searchText.length >= this.MIN_CHARS_TO_SEARCH) {
      this.searchIsLoading = true
      this.sharepointService.searchDocuments(this.siteId, this.searchText, this.localStorageService.getUserId()).subscribe(
        result => {
          this.sharePointSearch = result
          this.searchResults = result.documents
          this.searchIsLoading = false
        }, err => {
          this.searchIsLoading = false
          this.searchErrorMessage = 'An error occurred when searching. Please try again.'
        }
      )
    }
  }

  private setFirstBreadCrumb() {
    const firstBreadcrumb = new SharepointFolder()
    firstBreadcrumb.name = this.displayName
    this.breadcrumbsBaseTab = [firstBreadcrumb]
    this.breadcrumbsAiChatTab = [firstBreadcrumb]
  }

  goToFavorites() {
    this.logEvent('Favorites')
    this.resetFileClicked()
    this.showFavorites()
  }

  private showFavorites() {
    this.showingFavorites = true
    this.isLoadingDocuments = true

    this.setFirstBreadCrumb()

    const favoriteFolder = new SharepointFolder()
    favoriteFolder.name = this.myFavorites
    favoriteFolder.isFavorite = true

    this.breadcrumbsBaseTab.push(favoriteFolder)

    this.cancelOtherRequests()

    this.httpRequestSubscription = this.sharepointService.getFavorites(this.siteId, this.localStorageService.getUserId())
    .subscribe(favorites => {
      this.isLoadingDocuments = false
      this.files = favorites
      this.folders = []
    }, () => {
      this.isLoadingDocuments = false
      this.dialog.openErrorDialog()
    })
  }

  selectFolder(folder: SharepointFolder, skipLoadDocuments: boolean = false) {
    this.currentFolder = folder
    this.resetFileClicked()
    this.logEvent('Folder Selected', {path: this.getCurrentFolderPath() + folder.name})

    this.breadcrumbsBaseTab.push(folder)

    if (!skipLoadDocuments) {
      this.isLoadingDocuments = true
      this.cancelOtherRequests()
      this.httpRequestSubscription = this.sharepointService.getDocumentDetails(this.siteId, folder.id, this.localStorageService.getUserId())
      .subscribe(documents => {
        this.isLoadingDocuments = false
        this.folders = [].concat(documents.folders)
        this.files = [].concat(documents.files)
      }, () => {
        this.isLoadingDocuments = false
        this.dialog.openErrorDialog()
      })
    }

    const url = this.router.url + ';folderPath=' + this.getCurrentFolderPath()
    // Change the URL without navigate:
    this.location.go(url)
  }

  private getCurrentFolderPath() {
    let path = ''
    if (this.breadcrumbsBaseTab.length > 1) {
      for (let i = 1; i < this.breadcrumbsBaseTab.length; i++) {
        path += this.breadcrumbsBaseTab[i].name
        if (i !== this.breadcrumbsBaseTab.length - 1) {
          path += this.pathSeparator
        }
      }
    }
    return path
  }

  private setUrlForSharing(fileId: string): void {
    let url = '/sharepoint/' + this.siteId + ';siteId=' + this.siteId
    const segments: UrlSegment[] = this.route.snapshot.url
    if (segments.length > 0) {
      const params = segments.pop().parameterMap
      const displayNameParam = params.get('displayName')
      if (!!displayNameParam) {
        url += ';displayName=' + displayNameParam
      }
    }

    if (this.currentFolder && this.selectedTab === this.baseTab) {
      url += ';folderPath=' + this.getCurrentFolderPath()
    }

    url += ';fileId=' + encodeURIComponent(fileId)

    this.location.go(url)
  }

  openFileFromSearch(fileId: string, pathFromSearch) {
    const folders = pathFromSearch.split('Shared Documents/').pop().split('/')
    // gets rid of the file name
    folders.pop()

    const folderPath = folders.slice(0).join(this.pathSeparator)
    this.navigateToFile(folderPath, fileId)

    this.clearItemsFromList()
    this.logEvent('File Selected', {path: this.getCurrentFolderPath(), isFromSearch: true})
  }

  openFile(fileId: string) {
    this.clearSearch()
    this.setUrlForSharing(fileId)
    this.resetFileClicked()

    fileId = decodeURIComponent(fileId)
    let file = this.files.find(f => f.id === fileId + '==' || f.id === fileId)
    if (!file) {
      file = new SharepointFile()
      file.id = fileId
      file.siteId = this.siteId
      file.name = this.getFileNameFromFileId(fileId) || 'Shared File'
      file.favorite = this.isFavorite(file.id)
    }
    this.selectedFile = Object.assign(new SharepointFile(), file)

    this.breadcrumbsBaseTab.push(this.selectedFile)
    this.fileClicked = true
    this.useFileUrl = false

    this.logEvent('File Selected', {path: this.getCurrentFolderPath(), isFromSearch: false})
  }

  private getFileNameFromFileId(fileId: string): string {
    let fileName = null
    try {
      const decoded = atob(fileId)
      const index = decoded.lastIndexOf('/')
      if (index > 0) {
        fileName = decodeURIComponent(decoded.substring(index + 1))
      }
    } catch (e) {
      // do nothing
    }

    return fileName
  }

  navigateToFile(folderPath, fileId) {
    this.router.navigate(['/sharepoint/' + this.siteId, {
      siteId: this.siteId,
      displayName: this.displayName,
      folderPath: folderPath,
      fileId: encodeURIComponent(fileId)
    }])
  }

  toggleFavorite(file: SharepointFile, event: any, location: string) {
    if (!!event && !!event.stopPropagation) {
      event.stopPropagation()
    }
    file.favorite = !file.favorite

    const userId = this.localStorageService.getUserId()
    if (file.favorite) {
      this.sharepointService.addFavorite(file, userId).subscribe({
        next: () => {
          this.logEvent('Favorite Selection On', {file: file.name, location})
          this.loadStandaloneFavorites()
          this.makeFavoriteChangesInBothPlaces(location, file.id)
        }, error: () => {
          this.dialog.openErrorDialog('There was a problem adding this document to your favorites. Please try again.')
          file.favorite = !file.favorite
        }
      })
    } else {
      this.sharepointService.removeFavorite(file).subscribe(() => {
        this.logEvent('Favorite Selection Off', {file: file.name, location})
        this.loadStandaloneFavorites()
        this.makeFavoriteChangesInBothPlaces(location, file.id)
        if (this.showingFavorites) {
          this.showFavorites()
        }
      }, () => {
        this.dialog.openErrorDialog('There was a problem removing this document from your favorites. Please try again.')
        file.favorite = !file.favorite
      })
    }
  }

  showAiChatHeader() {
    return this.showFooterList.includes(this.siteId)
  }

  onTermsClick() {
    this.activityLogService.log('Chat - Footer - Terms click')
    this.showTermsOfUseDialog()
  }

  private showTermsOfUseDialog() {
    const modalRef = this.modal.open(ChatSplashComponent, ChatSplashComponent.ngbModalOptions)
    modalRef.componentInstance.setTermsOfUse(this.termsOfUse)
    modalRef.result.then(() => {
      // this.focusOnPrompt()
    })
  }

  onFeedbackClick(): void {
    this.activityLogService.log('Chat - Footer - Feedback click')
  }

  onAboutClick(): void {
    this.activityLogService.log('Chat - Footer - About click')
  }

  makeFavoriteChangesInBothPlaces(location, fileId) {
    // make change in both places
    if (location === 'File') {
      const fileInSidebar = this.files.find(f => f.id === fileId)
      fileInSidebar.favorite = !fileInSidebar.favorite
    } else if (location === 'List' && this.selectedFile) {
      this.selectedFile.favorite = !this.selectedFile.favorite
    }
  }

  onFileShared(url: string): void {
    this.logEvent('Share', {url})
  }

  selectBreadcrumb(index: number) {
    if (this.selectedTab === this.baseTab) {
      this.logEvent('Breadcrumb Selected', {
        breadcrumbLength: this.breadcrumbsBaseTab.length,
        index,
        breadcrumbs: this.breadcrumbsBaseTab
      })
      this.showingFavorites = false
      this.clearSearch()
      if (index === 0) {
        this.router.navigate(['/sharepoint/' + this.siteId, {
          siteId: this.siteId,
          displayName: this.displayName
        }])
        this.loadData(null, null)
      } else if (index !== this.breadcrumbsBaseTab.length - 1) {
        this.fileClicked = false
        const removedBreadcrumbs = this.breadcrumbsBaseTab.splice(index, this.breadcrumbsBaseTab.length - index)
        if (removedBreadcrumbs[0].isFavorite) {
          this.goToFavorites()
        } else {
          this.selectFolder(removedBreadcrumbs[0])
        }
        this.isSidebarCollapsed = false
      }
    } else {
      if (index === 0) {
        this.resetFileClicked()
      }
    }
  }

  private resetFileClicked(): void {
    if (this.fileClicked) {
      if (this.selectedTab === this.baseTab) {
        this.breadcrumbsBaseTab.pop()
      } else {
        this.breadcrumbsAiChatTab.pop()
      }
    }

    this.fileClicked = false
    this.selectedFile = null
    this.useFileUrl = false
    this.isSidebarCollapsed = false
  }

  isEmpty() {
    return this.folders.length === 0 && this.files.length === 0
  }

  isSelected(file: SharepointFile): boolean {
    return this.selectedFile && this.selectedFile.id === file.id
  }

  logEvent(event: string, properties?: any) {
    const fullProperties = properties || {}
    fullProperties.siteId = this.siteId
    this.activityLogService.log(`Sharepoint - ${this.displayName} - ${event}`)
  }

  isAIChatAvailable(): boolean {
    return this.siteId === RESOURCE_SITE.HUBBL
  }

  isAIChatActivated(): boolean {
    return this.isAIChatAvailable() && this.aiChatTabActivated
  }

  getAIChatContentCss(): string {
    let css = 'flex-grow-1 d-flex flex-column min-height-0'
    if (this.selectedTab !== this.aiChatTab || this.isSidebarCollapsed) {
      css = 'd-none'
    }

    return css
  }

  onTabSelected(tab: string): void {
    if (!this.isSidebarCollapsed) {
      this.resetFileClicked()
      this.selectedTab = tab
      this.aiChatTabActivated = (this.selectedTab === this.aiChatTab) || this.aiChatTabActivated
    }
  }

  getBaseTabName(): string {
    if (this.siteId === RESOURCE_SITE.HUBBL) {
      return 'Tip Sheet'
    } else if (this.siteId === RESOURCE_SITE.COVID) {
      return 'Resource'
    } else {
      return 'Document'
    }
  }

  getTabLabel(): string {
    let label = 'Show More'
    if (!this.isSidebarCollapsed) {
      if (this.selectedTab === this.baseTab) {
        if (this.siteId === RESOURCE_SITE.HUBBL) {
          label = 'Tip Sheets'
        } else if (this.siteId === RESOURCE_SITE.COVID) {
          label = 'Resources'
        } else {
          label = 'Documents'
        }
      } else {
        label = 'VUMC aiChat'
      }
    }

    return label
  }

  getSearchPlaceholder(): string {
    let placeholder = ''
    if (!this.selectedSearchFile && !this.isSidebarCollapsed) {
      placeholder = 'Search ' + this.getTabLabel()
    }

    return placeholder
  }

  shouldShowSearchInput(): boolean {
    return this.shouldShowFavoritesFolder()
  }

  shouldShowFavoritesFolder(): boolean {
    return !this.showingFavorites
      && (this.breadcrumbsBaseTab.length < 2 || (this.breadcrumbsBaseTab.length === 2 && !!this.selectedFile))
  }

  onChatCitationClicked(citation: ContentSource) {
    if (!citation) {
      this.resetFileClicked()
      return
    }

    this.setUrlForSharing(citation.externalId)

    const file = new SharepointFile()
    file.id = citation.externalId
    file.name = citation.title
    file.url = citation.url
    file.extension = 'pdf'
    file.favorite = this.isFavorite(file.id)
    this.selectedFile = Object.assign(new SharepointFile(), file)

    // check if a file is already open, if so get rid of that breadcrumb
    if (this.fileClicked) {
      this.breadcrumbsAiChatTab.pop()
    }

    this.breadcrumbsAiChatTab.push(this.selectedFile)
    this.fileClicked = true
    this.useFileUrl = true
  }

  getBreadcrumbs(): any[] {
    return this.selectedTab === this.baseTab ? this.breadcrumbsBaseTab : this.breadcrumbsAiChatTab
  }

  private loadStandaloneFavorites(): void {
    this.sharepointService.getFavorites(this.siteId, this.localStorageService.getUserId())
    .subscribe({
      next: (favorites: SharepointFile[]) => {
        this.favorites = favorites
      }
    })
  }

  private isFavorite(fileId: string): boolean {
    if (!!this.favorites && this.favorites.length > 0) {
      return !!this.favorites.find(f => f.id === fileId + '==' || f.id === fileId)
    }

    return false
  }
}
