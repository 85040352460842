<div class="fill-height bg-vumc2go app-page">
  <div class="d-none">{{appVersion}}</div>
  <nav class="bg-primary navbar px-3" *ngIf="isAuthenticated()">
    <a class="navbar-brand pointer" routerLink="/homepage" href="javascript:void(0)">
      <img class="pointer" src="assets/vumc2gologo.png" alt="vumc2go" width="85" height="30">
    </a>

    <div class="d-flex"><!-- middle nav items-->
      <div class="nav-item">
        <a class="nav-link link" routerLink="/sharepoint/{{siteTypes.HUBBL}}"
           routerLinkActive="active-link"
           (click)="onResources(siteTypes.HUBBL)">
          Hubbl eStar Tip Sheets
        </a>
      </div>
      <div class="nav-item ms-4">
        <a class="nav-link link" routerLink="/sharepoint/{{siteTypes.COVID}}"
           routerLinkActive="active-link"
           (click)="onResources(siteTypes.COVID)">
          COVID-19 Resources
        </a>
      </div>
      <!--
      <li class="nav-item dropdown">
        <a class="nav-link link dropdown-toggle" href="javascript:void(0)">More</a>
        <ul class="dropdown-menu">
          <li>
            <a class="dropdown-item" href="javascript:void(0)" *ngIf="shouldShowFeedback()"
               (click)="onFeedback()">Feedback</a>
          </li>
          <li>
            <a class="dropdown-item" href="javascript:void(0)" (click)="onCreativeCommons()">Creative
              Commons</a>
          </li>
          <li>
            <a class="dropdown-item" href="javascript:void(0)" (click)="onLogout()">Logout</a>
          </li>
        </ul>
      </li>
      -->
    </div>

    <div class="nav-item">
      <a class="nav-link app-log-out" href="javascript:void(0)" (click)="onLogout()">Log out</a>
    </div>
  </nav>

  <div class="container-fluid bg-vumc2go app-container" *ngIf="shouldShowRouterOutlet(); else showSessionExpired">
    <router-outlet></router-outlet>
  </div>
  <ng-template #showSessionExpired>
    <app-login [isSessionExpired]="true"></app-login>
  </ng-template>
</div>
