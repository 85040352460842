import {Injectable} from '@angular/core'
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http'
import {Observable} from 'rxjs/internal/Observable'

@Injectable()
export class ApiJsonInterceptor implements HttpInterceptor {
  constructor() {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authReq = req.clone({
      headers: req.headers
      .append('Content-Type', 'application/json')
      .append('Accept', 'application/json')
    })

    return next.handle(authReq)
  }
}
