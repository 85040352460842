<div *ngIf="messages.length === 0" class="text-muted mx-3 text-center my-3">
  Use VUMC aiChat as your virtual assistant to help gather proper documents in Tip Sheets. Get
  summaries, processes and quick responses with linked citations.
  <br/><br/>
  Example, ask aiChat <i>“How do you set Autoconnect for ‘eStar’ VMware?”</i>
</div>
<div *ngIf="messages.length > 0" class="content-scroll min-height-0 my-3">
  <div *ngFor="let message of messages; index as index; last as last;"
       class="card message-role-{{message.role}} chat-message-{{index}}"
       [ngClass]="{'mb-3': !last}">
    <div class="card-body">

      <div class="mb-2">
        <div *ngIf="isRoleUser(message); else assistantHeader">
          <i class="fas fa-user"></i>
          You
        </div>
        <ng-template #assistantHeader>
          <i class="fas fa-comment-medical"></i>
          VUMC aiChat
        </ng-template>
      </div>
      <div class="d-flex">
        <div class="me-auto overflow-auto"
             [innerHTML]="getContentWithInternalCitations(message, index)"></div>
        <div class="ms-1">
          <i *ngIf="message !== copiedMessage"
             (click)="copyCardText(message)"
             class="fas fa-copy pointer"></i>
          <i *ngIf="message === copiedMessage" class="fas fa-check"></i>
        </div>
      </div>

      <div class="d-flex align-items-start mt-3 fst-italic"
           *ngIf="message.sources && message.sources.length > 0">
        Citations
        <div>
          <ng-template ngFor let-citation let-citationIndex="index" [ngForOf]="message.sources">
            <a href="javascript:void(0)"
               class="ms-3 d-inline-block"
               [ngClass]="{'btn btn-sm btn-primary py-0': citation.externalId === selectedCitationId}"
               (click)="onCitationClicked(citation)">
              {{ citationIndex + 1 }}. {{ citation.title }}
            </a>
          </ng-template>
        </div>
      </div>
      <div class="mt-1" *ngIf="isRoleAssistant(message) && message.noInfoFound">
        <p class="text-muted clear-conversation">I'm sorry, I am having trouble answering your question.
          Try asking your question in a different way or start a new conversation.
        </p>
      </div>
    </div>
  </div>

  <div class="card message-role-assistant mt-3" *ngIf="isProcessingPrompt">
    <div class="card-body">
      <div class="mb-2">
        <i class="fas fa-comment-medical"></i>
        VUMC aiChat
      </div>
      <i class="fa fa-spinner fa-spin fa-1x"></i> loading response
    </div>
  </div>
</div>

<div>

  <div class="mt-2 mb-2 d-inline-flex" *ngIf="messages.length > 0">
    <i class="fa fa-undo fa-sm pointer me-1 align-content-center"></i>
    <p class="fw-bold clear-conversation pointer mt-0 mb-0" (click)="startNewThread()">
      Clear Conversation
    </p>
  </div>

  <form class="mb-1" [formGroup]="currentForm">
    <div class="input-group mb-1">
    <textarea id="inputPrompt"
              class="form-control chat-custom-input"
              formControlName="prompt"
              [placeholder]="getPromptPlaceholder()"
              (keydown.enter)="onSubmitPrompt()"
              (input)="onPromptChange()"
              autocomplete="off"></textarea>
      <div class="input-group-btn-icon-custom">
        <button class="submit-button" [disabled]="isProcessingPrompt">
          <i (click)="onSubmitPrompt()" disabled="true"
             class="fa fas fa-lg fa-arrow-alt-circle-right pointer"></i>
        </button>
      </div>
    </div>
  </form>

  <div class="mt-2 text-center full-experience">
    <img src="assets/images/aichat-logo-header.png"
         height="36px"
         width="84px"
         class="pointer"
         (click)="openAiChat()"
         title="Get the full experience at https://aichat.app.vumc.org/#/chat">
  </div>
</div>
