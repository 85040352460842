import {Injectable} from '@angular/core'
import {NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap'
import {from, Observable} from 'rxjs'
import {DialogComponent} from '../components/dialog/dialog.component'

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  public static AUTHENTICATION_ERROR = 'Your session has expired. Please refresh this page.'
  public static AUTHENTICATION_ERROR_SAVE = 'Your session has expired, and we\'re unable to save your changes. Please refresh this page.'

  constructor(private modal: NgbModal) {
  }

  openInfoDialogWithCallBack(title: string, message: string, cancelBtnLabel: string, actionBtnLabel: string, actionBtnClass: string): Observable<boolean> {
    const modalRef = this.modal.open(DialogComponent)
    modalRef.componentInstance.title = title
    modalRef.componentInstance.message = message
    modalRef.componentInstance.actionButtonTitle = actionBtnLabel
    modalRef.componentInstance.cancelButtonTitle = cancelBtnLabel
    modalRef.componentInstance.showCancelBtn = false
    modalRef.componentInstance.actionButtonClass = actionBtnClass
    const promise = modalRef.result.then((result) => {
        return result.trim() === actionBtnLabel
      },
      () => {
        return false
      }
    )

    return from(promise)
  }

  openDeleteConfirmation(message: string, actionBtnLabel = 'Yes, delete', cancelBtnLabel: string = 'Cancel'): Observable<boolean> {

    const modalRef = this.modal.open(DialogComponent)
    modalRef.componentInstance.title = ''
    modalRef.componentInstance.message = message
    modalRef.componentInstance.actionButtonTitle = actionBtnLabel
    modalRef.componentInstance.actionButtonClass = 'btn-app-danger'
    modalRef.componentInstance.cancelButtonTitle = cancelBtnLabel
    const promise = modalRef.result.then((result) => {
        return result.trim() === actionBtnLabel
      },
      () => {
        return false
      }
    )

    return from(promise)
  }

  openErrorDialog(message?: string): NgbModalRef {
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false
    }
    const modalRef = this.modal.open(DialogComponent, ngbModalOptions)
    modalRef.componentInstance.title = 'Something went wrong'
    modalRef.componentInstance.message = message || 'There was an unexpected problem, please try again.'
    modalRef.componentInstance.actionButtonTitle = ''
    modalRef.componentInstance.cancelButtonTitle = 'Okay'
    return modalRef
  }

  openInfoDialog(message: string, title?: string): NgbModalRef {
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false
    }
    const modalRef = this.modal.open(DialogComponent, ngbModalOptions)
    modalRef.componentInstance.title = title || ''
    modalRef.componentInstance.message = message
    modalRef.componentInstance.actionButtonTitle = ''
    modalRef.componentInstance.cancelButtonTitle = 'Okay'
    return modalRef
  }
}
