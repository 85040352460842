import {Injectable} from '@angular/core'

export interface IActivityLogService {
  log(event: string, properties?: any)
}

@Injectable({
  providedIn: 'root'
})
export class ActivityLogService implements IActivityLogService {

  constructor() {
  }

  log(event: string, properties?: any) {
    if (console) {
      console.log(event, properties)
    }
  }
}
