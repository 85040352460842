import {EventEmitter, Injectable, Output} from '@angular/core'
import {LocalStorageService} from './local-storage.service'
import {Environment} from '../app.environment'
import {HttpClient} from '@angular/common/http'

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  public static readonly ROLE_EVENTS = 'events'

  @Output() userRolesUpdated: EventEmitter<boolean> = new EventEmitter<boolean>()
  @Output() userRolesLoading: EventEmitter<boolean> = new EventEmitter<boolean>()

  constructor(private localStorageService: LocalStorageService,
              private env: Environment,
              private http: HttpClient) {
  }

  updateCurrentUserRoles(): void {
    if (!!this.localStorageService.getToken() && this.localStorageService.getUserRoles().length === 0) {
      this.userRolesLoading.emit(true)
      this.http.get<string[]>(this.env.getCurrentUserRolesUrl()).subscribe({
        next: (roles) => {
          this.localStorageService.setUserRoles(roles.map((role) => role.toLowerCase()))
          this.userRolesUpdated.emit(true)
          this.userRolesLoading.emit(false)
        }, error: () => {
          this.userRolesUpdated.emit(true)
          this.userRolesLoading.emit(false)
        }
      })
    }
  }

  hasRole(role: string): boolean {
    return this.getLocalRoles().indexOf(role) >= 0
  }

  private getLocalRoles(): string[] {
    return this.localStorageService.getUserRoles()
  }

  hasEventsRole(): boolean {
    return this.hasRole(RoleService.ROLE_EVENTS)
  }
}
