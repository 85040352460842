import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core'
import {ActivityLogService} from '../../services/activity-log.service'
import {FormBuilder, FormGroup, Validators} from '@angular/forms'
import {NgbModal} from '@ng-bootstrap/ng-bootstrap'
import {DialogService} from '../../services/dialog.service'
import {ChatService} from '../../services/chat.service'
import {Message} from './message'
import {ChatRequest} from './chat-request'
import {ContentSource} from './content-source'
import {ChatResponse} from './chat-response'
import {TermsOfUse} from './chat-splash/terms-of-use'
import {ChatSplashComponent} from './chat-splash/chat-splash.component'
import {ChatTypeConfiguration} from './chat-type-configuration'

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit {

  private static readonly chatTypeGeneral = 'general'
  private static readonly chatTypeTipsheets = 'tipsheets'
  private static readonly internalCitationBase = 'internalCitation/'

  private readonly messageRoleUser = 'user'
  private readonly messageRoleAssistAssistant = 'assistant'
  private readonly MAX_CONTENT_TOKENS = 8000 // 9331

  @Input() siteId: string
  @Output() citationClicked: EventEmitter<ContentSource> = new EventEmitter<ContentSource>()

  copiedMessage: Message

  currentForm: FormGroup
  messages: Message[] = []
  selectedCitationId: string

  isProcessingPrompt = false
  chatRequestSubscription: any
  hasShownResponseErrorDialog = false
  userErrorMessage = ''
  chatRequest = new ChatRequest()

  termsOfUse: TermsOfUse

  constructor(private chatService: ChatService,
              private activityLogService: ActivityLogService,
              private dialog: DialogService,
              private modal: NgbModal,
              private fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.buildForm()
    this.getChatType()
    this.loadTermsOfUse()
  }

  buildForm() {
    this.currentForm = this.fb.group({
      prompt: [null, [Validators.required]]
    }, {})
    this.currentForm.disable()
  }

  private loadTermsOfUse() {
    this.chatService.getTermsOfUse().subscribe({
      next: termsOfUse => {
        this.termsOfUse = termsOfUse
        if (!this.termsOfUse.acknowledged) {
          this.showTermsOfUseDialog()
        } else {
          this.focusOnPrompt()
          this.currentForm.enable()
        }
      },
      error: () => {
        this.showTermsOfUseDialog()
      }
    })
  }

  private showTermsOfUseDialog() {
    const modalRef = this.modal.open(ChatSplashComponent, ChatSplashComponent.ngbModalOptions)
    modalRef.componentInstance.setTermsOfUse(this.termsOfUse)
    modalRef.result.then(() => {
      this.focusOnPrompt()
      this.currentForm.enable()
    })
  }

  private getChatType() {
    this.chatService.getChatTypeBySiteId(this.siteId).subscribe({
      next: (config: ChatTypeConfiguration) => {
        this.chatRequest.chatType = config.chatType
      }, error: () => {
        this.dialog.openErrorDialog('An error occurred getting the chat type.')
      }
    })
  }

  onPromptChange() {
    this.autoGrowPrompt()
  }

  focusOnPrompt(): void {
    const element = document.getElementById('inputPrompt')
    if (!!element) {
      setTimeout(() => {
        element.focus()
      }, 0)
    }
  }

  copyCardText(message) {
    let content = message.content
    // replace br with /n
    content = content.replace(/<br ?\/?>/g, '\n')
    // get rid of html tags
    content = content.replace(/<[^>]*>/g, '')

    // copies
    const selBox = document.createElement('textarea')
    selBox.value = content
    document.body.appendChild(selBox)
    selBox.select()
    document.execCommand('copy')
    document.body.removeChild(selBox)
    this.copiedMessage = message
    // check mark to go away
    setTimeout(() => {
      this.copiedMessage = null
    }, 500)
  }

  private getPromptValue(): string {
    return this.currentForm.get('prompt')?.value
  }

  getPromptPlaceholder(): string {
    this.autoGrowPrompt()
    return 'Ask me questions about Tip Sheet documents'
  }

  autoGrowPrompt() {
    const textArea = document.getElementById('inputPrompt')
    // resets for backspace
    textArea.style.height = '0px'
    // resizes based off screen size
    textArea.style.height = textArea.scrollHeight + 'px'
  }

  addInternalCitationClickListeners() {
    setTimeout(() => {
      document.querySelectorAll('a.internal-citation-clickable').forEach(el => {
        const matches = el.className.match('internal-citation-(\\d+)-(\\d+)')
        if (!!matches && matches.length === 3) {
          const messageIndex = +matches[1]
          const sourceIndex = +matches[2]
          el.addEventListener('click', () => {
            this.onInternalCitationClick(messageIndex, sourceIndex)
          })
        }
      })
    }, 10)
  }

  onInternalCitationClick(messageIndex: number, sourceIndex: number) {
    const message = this.messages[messageIndex]
    const source = message.sources[sourceIndex]
    this.onCitationClicked(source)
  }

  onCitationClicked(citation: ContentSource) {
    this.activityLogService.log('Chat - Citation Clicked', {url: citation.url, title: citation.title})
    this.selectedCitationId = citation.externalId
    this.citationClicked.emit(citation)
  }

  onSubmitPrompt(): void {
    this.clearUserErrorMessage()
    const prompt = this.getPromptValue()

    if (!!prompt && !this.isProcessingPrompt) {
      this.isProcessingPrompt = true
      this.currentForm.disable()

      this.messages.push({
        noInfoFound: false,
        content: prompt,
        role: this.messageRoleUser,
        modelVersion: null,
        usage: null,
        sources: []
      })
      this.cancelOtherRequests()
      this.chatRequest.messages = this.messages

      this.activityLogService.log('Chat - prompt', {
        threadLength: this.messages.length,
        chatType: this.chatRequest.chatType
      })

      this.chatRequestSubscription = this.chatService.chat(this.chatRequest).subscribe({
        next: (response: ChatResponse) => {
          if (!!response && !!response.messages && response.messages.length > 0) {
            for (const message of response.messages) {
              this.messages.push(message)
            }
          }

          this.activityLogService.log('Chat - response', {
            threadLength: this.messages.length,
            completionTokens: response?.usage?.completionTokens,
            promptTokens: response?.usage?.promptTokens,
            totalTokens: response?.usage?.totalTokens,
            chatType: this.chatRequest.chatType
          })

          if (response.userErrorMessage) {
            if (!this.hasShownResponseErrorDialog) {
              this.dialog.openErrorDialog(response.userErrorMessage)
              this.hasShownResponseErrorDialog = true
            } else {
              this.userErrorMessage = response.userErrorMessage
            }
          }

          this.isProcessingPrompt = false
          this.resetPrompt()
          this.scrollToLastUserMessage()
          this.addInternalCitationClickListeners()
        }, error: (err: any) => {
          this.messages.pop()
          this.isProcessingPrompt = false
          this.currentForm.enable()
          const modalRef = this.dialog.openErrorDialog(err?.error?.message || null)
          modalRef.result.then(() => {
              this.focusOnPrompt()
            }
          )
        }
      })
      this.scrollToLastUserMessage()
    } else {
      // tell user to calm down
    }
  }

  getContentWithInternalCitations(message: Message, messageIndex: number): string {
    let content = message.content
    if (message.role === this.messageRoleAssistAssistant) {
      for (let sourceIndex = 0; sourceIndex < message.sources.length; sourceIndex++) {
        const source = message.sources[sourceIndex]
        const titleReference = '[' + source.title + ']'
        const replacementHtml = `[<a class="pointer internal-citation-clickable internal-citation-${messageIndex}-${sourceIndex}">${sourceIndex + 1}</a>]`
        content = content.replaceAll(titleReference, replacementHtml)
      }
    }

    return content
  }

  clearUserErrorMessage() {
    this.userErrorMessage = ''
  }

  resetPrompt() {
    this.currentForm.reset()
    this.currentForm.enable()
    this.cancelOtherRequests()
    this.isProcessingPrompt = false
    this.onPromptChange()
    this.focusOnPrompt()
  }

  cancelOtherRequests() {
    if (this.chatRequestSubscription) {
      this.chatRequestSubscription.unsubscribe()
    }
  }

  isRoleUser(message: Message): boolean {
    return !!message && message.role === this.messageRoleUser
  }

  isRoleAssistant(message: Message): boolean {
    return !!message && message.role === this.messageRoleAssistAssistant
  }

  startNewThread(): void {
    this.activityLogService.log('Chat - Start New Thread')
    this.cancelOtherRequests()
    this.resetPrompt()
    this.messages = []
    this.hidePdfViewer()
    this.focusOnPrompt()
  }

  hidePdfViewer() {
    this.selectedCitationId = null
    this.citationClicked.emit(null)
  }

  scrollToLastUserMessage(): void {
    let index = -1
    if (this.messages.length > 0) {
      for (let i = this.messages.length - 1; i >= 0; i--) {
        if (this.messages[i].role === this.messageRoleUser) {
          index = i
          break
        }
      }
    }

    if (index >= 0) {
      setTimeout(() => {
        const classElement = document.getElementsByClassName('chat-message-' + index)
        if (classElement.length > 0) {
          classElement[0].scrollIntoView()
        }
      }, 50)
    }
  }

  openAiChat(): void {
    window.open('https://aichat.app.vumc.org/#/chat', '_blank')
  }
}
