import { Injectable } from '@angular/core';
import {Observable} from 'rxjs/internal/Observable'
import {MoreConfiguration} from '../../models/more-configuration'
import {HttpClient} from '@angular/common/http'
import {Environment} from '../app.environment'
import {LocalStorageService} from './local-storage.service'

@Injectable({
  providedIn: 'root'
})
export class MoreConfigurationService {

  constructor(private httpClient: HttpClient,
              private environment: Environment,
              private localStorageService: LocalStorageService) { }

  getMoreConfiguration(): Observable<MoreConfiguration> {
    const url = this.environment.getFeedbackResourceUrl()
    return this.httpClient.get<MoreConfiguration>(url)
  }
}
